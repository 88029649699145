<template>
    <div class="supplier">
        <Top :current="currentIndex" />

        <div class="bor">
            <div class="img-intr">
                <img src="../assets/img/img-intr-1.png" alt="">
                <div class="img-intr-txt">
                    <h1>{{$t('supplier.lan-img-intr-1')}}</h1>
                    <p>{{$t('supplier.lan-img-intr-2')}}</p>
                    <p>{{$t('supplier.lan-img-intr-3')}}</p>
                    <p>{{$t('supplier.lan-img-intr-4')}}</p>
                </div>
            </div>

            <div class="img-intr">
                <img src="../assets/img/img-intr-2.png" alt="">
                <div class="img-intr-txt">
                    <h1>{{$t('supplier.lan-img-intr-5')}}</h1>
                    <p class="img-intr-txt-p">{{$t('supplier.lan-img-intr-6')}}</p>
                </div>
            </div>

            <div class="middle">
                <p class="til">
                    <em></em>
                    <span>{{$t('supplier.lan-til-1')}}</span>
                    <i></i>
                </p>
                <div class="advantage">
                    <p>{{$t('supplier.lan-adv-1')}}</p>
                    <p>{{$t('supplier.lan-adv-2')}}</p>
                    <p>{{$t('supplier.lan-adv-3')}}</p>
                    <p>{{$t('supplier.lan-adv-4')}}</p>
                </div>
            </div>

            <div class="bottom">
                <p class="til">
                    <em></em>
                    <span>{{$t('supplier.lan-til-2')}}</span>
                    <i></i>
                </p>
                <div class="inpt">
                    <dl>
                        <dt><span>*</span>{{$t('supplier.lan-name')}}</dt>
                        <dd>
                            <input type="text" maxlength="30" :class="nameError ? 'error' : ''" v-model="name" />
                            <p v-if="nameError">{{$t('supplier.lan-name-err')}}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt><span>*</span>{{$t('supplier.lan-pho')}}</dt>
                        <dd>
                            <input type="text" maxlength="20" :class="phoneError||phoneErrorFormat ? 'error' : ''" v-model="phone" />
                            <p v-if="phoneError">{{$t('supplier.lan-pho-err')}}</p>
                        </dd>
                    </dl>
                </div>
                
                <a class="butt" @click="butt">{{$t('supplier.lan-sure')}}</a>
            </div>
            <ul class="brand">
                <li><img src="../assets/img/icon-xm.png" alt="" /></li>
                <li><img src="../assets/img/icon-ty.png" alt="" /></li>
                <li><img src="../assets/img/icon-tm.png" alt="" /></li>
                <li><img src="../assets/img/icon-jd.png" alt="" /></li>
                <li><img src="../assets/img/icon-yp.png" alt="" /></li>
            </ul>
        </div>

        <Footer />

        <div class="pop" v-if="popIs">
            <p>{{$t('supplier.lan-suc')}}</p>
        </div>
    </div>
</template>

<script>
import Top from '@/components/Top.vue'
import Footer from "@/components/Footer.vue"

export default {
    components: {
        Top,
        Footer
    },
    name: "Supplier",
    data() {
        return {
            currentIndex: 3,
            popIs: false,
            name: '',
            phone: '',
            nameError: false,
            phoneError: false,
            phoneErrorFormat: false
        }
    },
    mounted() {
        
    },
    methods: {
        butt(){
            let name = this.name.replace(/(^\s*)|(\s*$)/g, "");
            let phone = this.phone.replace(/(^\s*)|(\s*$)/g, "");

            if(name === ''){
                this.nameError = true
            } else{
                this.nameError = false
            }

            if(phone === ''){
                this.phoneError = true
            } else{
                this.phoneError = false
            }
            
            if(this.nameError === false && this.phoneError === false){
                this.postMes()
            } else{
                console.log("有错误")
            }
        },
        async postMes(){
            try {
                //正式：https://ucsvr.gosund.com
                //测试：https://test-uc-web.gosund.com
                await this.$axios.post('https://test-uc-web.gosund.com/uc-api/partner',{
                    source: '电小酷官网',
                    contactPerson: this.name,
                    phone: this.phone
                }).then(res => {
                    //console.log(res);
                    if(res.code === 200){
                        this.popIs = true
                        setTimeout(() => { 
                            this.popIs = false
                            this.name = ''
                            this.phone = ''
                        }, 3000)
                    } else{
                        alert("Failed to send")
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.bor{
    width: 100%;
}
.img-intr{
    width: 100%;
    margin-bottom: 80px;
    position: relative;
    img{
        display: block;
        width: 100%;
    }
    .img-intr-txt{
        width: 1200px;
        padding: 30px 120px;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, .6);
        text-align: center;
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        h1{
            line-height: 47px;
            font-size: 36px;
            color: #333;
            margin-bottom: 20px;
        }
        p{
            line-height: 36px;
            font-size: 20px;
            color: #333;
        }
    }
}
.middle{
    width: 1028px;
    margin: 80px auto 0;
    .advantage{
        width: 1140px;
        margin: 50px auto 60px;
        p{
            width: 100%;
            line-height: 21px;
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
}
.bottom{
    width: 100%;
    margin: 80px 0 0;
    .inpt{
        width: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        dl{
            width: 330px;
            margin-right: 66px;
            display: flex;
            dt{
                width: 130px;
                height: 30px;
                line-height: 30px;
                text-align: right;
                font-size: 14px;
                padding-right: 10px;
                box-sizing: border-box;
                span{
                    color: red;
                }
            }
            dd{
                width: 200px;
                height: 30px;
                position: relative;
                input{
                    width: 198px;
                    height: 28px;
                    border: 1px solid #C8CDCB;
                    border-radius: 4px;
                    padding-left: 10px;
                    box-sizing: border-box;
                }
                input:focus{
                    border: 1px solid #07C160;
                }
                input.error{
                    border-color: red;
                }
                p{
                    width: 100%;
                    line-height: 14px;
                    font-size: 12px;
                    color: red;
                }
            }
        }
    }
    .butt{
        display: block;
        width: 267px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        background-color: #333333;
        border-radius: 4px;
        margin: 50px auto 0;
        &:hover{
            cursor: pointer;
            background-color: rgba(0, 0, 0, .6);
        }
    }
}
.til{
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    em{
        width: 160px;
        height: 1px;
        background-image: linear-gradient(to right, #fff , #9A9A9A);
    }
    i{
        width: 160px;
        height: 1px;
        background-image: linear-gradient(to right, #9A9A9A, #fff);
    }
    span{
        width: auto;
        padding: 0 10px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 30px;
        background-color: #fff;
    }
}
.brand{
    width: 1200px;
    margin: 80px auto;
    display: flex;
    justify-content: space-between;
    li{
        width: 216px;
        height: 92px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
.mrt80{
    margin-top: 80px!important;
}
.pop{
    width: 300px;
    height: 130px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    box-shadow: 0 5px 16px 0 rgba($color: #000000, $alpha: 0.06);
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    p{
        width: 100%;
        line-height: 18px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }
}

.tops{
    background-color: initial;
    border: none;
    .nav li a{
        color: #fff;
    }
    .nav li:hover a{
        color: #fff;
        font-weight: bold;
    }
    .nav li.active a{
        color: #fff;
        font-weight: bold;
    }
    .nav li a em{
        background-color: initial!important;
    }
    .res{
        color: #fff;
    }
    &:hover{
        background-color: #fff;
        border-bottom: 1px solid #DBDBDB;
        .nav li a{
            color: #000;
        }
        .nav li:hover a{
            color: #07C160;
            font-weight: bold;
        }
        .nav li.active a{
            color: #000;
            font-weight: bold;
        }
        .nav li a em{
            background-color: #07C160!important;
        }
        .res{
            color: #000;
        }
    }
}
.topb{
    color: #000;
}

@media screen and (max-width: 1200px) {
    .bor{
        width: 91.4%;
        margin: 24px auto 0;
    }
    .img-intr{
        width: 100%;
        margin-bottom: 32px;
        position: relative;
        img{
            display: block;
            width: 100%;
        }
        .img-intr-txt{
            width: 100%;
            padding: 0;
            box-sizing: border-box;
            background-color: rgba(255, 255, 255, 0);
            position: initial;
            transform: translateX(0);
            margin-top: 24px;
            margin-bottom: 16px;
            h1{
                line-height: 24px;
                font-size: 18px;
                color: #333;
                margin-bottom: 16px;
            }
            p{
                line-height: 19px;
                font-size: 14px;
                color: #333;
                margin-bottom: 16px;
            }
            p.img-intr-txt-p{
                text-align: left;
                text-indent: 2em;
            }
        }
    }
    .middle{
        width: 100%;
        margin: 16px auto 0;
        .advantage{
            width: 100%;
            margin: 24px auto 0;
            p{
                width: 100%;
                line-height: 19px;
                font-size: 14px;
                margin-bottom: 19px;
            }
        }
    }
    .bottom{
        margin: 32px 0 0;
        .inpt{
            margin-top: 24px;
            display: initial;
            dl{
                width: 100%;
                margin-right: 0;
                margin-top: 24px;
                display: flex;
                dt{
                    width: 26%;
                    height: auto;
                    line-height: 40px;
                    text-align: left;
                    font-size: 14px;
                    padding-right: 0;
                    box-sizing: border-box;
                    span{
                        color: red;
                    }
                }
                dd{
                    width: 74%;
                    height: auto;
                    position: relative;
                    input{
                        width: 100%;
                        height: 38px;
                        border: 1px solid #C8CDCB;
                        border-radius: 4px;
                        padding-left: 10px;
                        box-sizing: border-box;
                    }
                    input:focus{
                        border: 1px solid #07C160;
                    }
                    input.error{
                        border-color: red;
                    }
                    p{
                        width: 100%;
                        line-height: 14px;
                        font-size: 12px;
                        color: red;
                    }
                }
            }
        }
        .butt{
            display: block;
            width: 267px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            background-color: #333333;
            border-radius: 4px;
            margin: 50px auto 0;
            &:hover{
                cursor: pointer;
                background-color: rgba(0, 0, 0, .6);
            }
        }
    }
    .til{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        position: relative;
        em{
            width: 36%;
            height: 1px;
            background-image: linear-gradient(to right, #fff , #9A9A9A);
        }
        i{
            width: 36%;
            height: 1px;
            background-image: linear-gradient(to right, #9A9A9A, #fff);
        }
        span{
            width: auto;
            padding: 0 2%;
            box-sizing: border-box;
            height: auto;
            line-height: 24px;
            text-align: center;
            font-size: 16px;
            background-color: #fff;
        }
    }
    .brand{
        width: 100%;
        margin: 32px auto 40px;
        display: flex;
        justify-content: space-between;
        li{
            width: 18%;
            height: auto;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
