<template>
    <div class="test">
        
    </div>
</template>

<script>
export default {
    name: "Test",
    data() {
        return {
            
        }
    },
    mounted() {
        
    },
    methods: {
        
    }
};
</script>

<style lang="scss" scoped>
.adsbygoogle{
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.adsbygoogles{
    display: block;
    width: 100%;
    height: 200px;
    overflow: hidden;
}
</style>
