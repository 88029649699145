import { render, staticRenderFns } from "./Mall.vue?vue&type=template&id=0cb3a6a4&scoped=true"
import script from "./Mall.vue?vue&type=script&lang=js"
export * from "./Mall.vue?vue&type=script&lang=js"
import style0 from "./Mall.vue?vue&type=style&index=0&id=0cb3a6a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cb3a6a4",
  null
  
)

export default component.exports