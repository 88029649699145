<template>
    <div class="about">
        <Top :current="currentIndex" />

        <div class="bor">
            <div class="intr">
                <img src="../assets/img/icon-intr.png" alt="">
                <div class="text">
                    <p>{{$t('about.lan-intr-1')}}</p>
                    <p>{{$t('about.lan-intr-2')}}</p>
                </div>
            </div>

            <ul class="culture">
                <li>
                    <img src="../assets/img/icon-intr-1.png" alt="" />
                    <div class="count">
                        <h1>{{$t('about.lan-mission')}}</h1>
                        <p>{{$t('about.lan-mission-det')}}</p>
                    </div>
                </li>
                <li>
                    <img src="../assets/img/icon-intr-2.png" alt="" />
                    <div class="count">
                        <h1>{{$t('about.lan-vision')}}</h1>
                        <p>{{$t('about.lan-vision-det')}}</p>
                    </div>
                </li>
                <li>
                    <img src="../assets/img/icon-intr-3.png" alt="" />
                    <div class="count">
                        <h1>{{$t('about.lan-values')}}</h1>
                        <p class="count-p" v-html="txt"></p>
                        <p class="count-p-mob" v-html="txtMob"></p>
                    </div>
                </li>
            </ul>

            <p class="til">
                <em></em>
                <span>{{$t('about.lan-dev')}}</span>
                <i></i>
            </p>

            <div class="develop">
                <div class="main">
                    <em class="icon-top"><img src="../assets/img/icon-dev.png" alt="" /></em>
                    <dl>
                        <dt>
                            <h3>2017</h3>
                            <i></i>
                        </dt>
                        <dd>
                            <span></span>
                            <p>{{$t('about.lan-2017')}}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <h3>2018</h3>
                            <i></i>
                        </dt>
                        <dd>
                            <span></span>
                            <p>{{$t('about.lan-2018')}}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <h3>2019</h3>
                            <i></i>
                        </dt>
                        <dd>
                            <span></span>
                            <p>{{$t('about.lan-2019')}}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <h3>2020</h3>
                            <i></i>
                        </dt>
                        <dd>
                            <span></span>
                            <p>{{$t('about.lan-2020')}}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <h3>2021<p>{{$t('about.lan-3y')}}</p></h3>
                            <i></i>
                        </dt>
                        <dd>
                            <span></span>
                            <p>{{$t('about.lan-20213')}}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <h3>2021<p>{{$t('about.lan-6y')}}</p></h3>
                            <i></i>
                        </dt>
                        <dd>
                            <span></span>
                            <p>{{$t('about.lan-20216')}}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <h3>2021<p>{{$t('about.lan-7y')}}</p></h3>
                            <i></i>
                        </dt>
                        <dd>
                            <span></span>
                            <p>{{$t('about.lan-20217')}}</p>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <h3>2021<p>{{$t('about.lan-7y')}}</p></h3>
                            <i></i>
                        </dt>
                        <dd>
                            <span></span>
                            <p>{{$t('about.lan-20218')}}</p>
                        </dd>
                    </dl>
                </div>
            </div>

            <ul class="connect">
                <p><i><img src="../assets/img/icon-phone.png" alt=""></i><span>{{$t('about.lan-phone')}}：0755-86165826</span></p>
                <p class="b"><i><img src="../assets/img/icon-addre.png" alt=""></i><span>{{$t('about.lan-address')}}：{{$t('about.lan-address-det')}}</span></p>
            </ul>
        </div>

        <Footer />
    </div>
</template>

<script>
import Top from '@/components/Top.vue'
import Footer from "@/components/Footer.vue"

export default {
    components: {
        Top,
        Footer
    },
    name: "About",
    data() {
        return {
            currentIndex: 4,
            txt: "",
            txtMob: ""
        }
    },
    mounted() {
        let id = this.$route.query.aboutId;
        if(id === '1'){
            this.scroll(0)
            //document.getElementById('intr').scrollIntoView({ block: 'start', behavior: 'smooth' })
        } else if(id === '2'){
            const thisTop = document.querySelector('.culture').offsetTop - 80
            this.scroll(thisTop)
        } else if(id === '3'){
            const thisTop = document.querySelector('.til').offsetTop - 80
            this.scroll(thisTop)
        } else if(id === '4'){
            const thisTop = document.querySelector('.connect').offsetTop - 80
            this.scroll(thisTop)
        }

        this.txt = this.$t('about.lan-values-det')
        this.txtMob = this.$t('about.lan-values-det-mob')
    },
    methods: {
        scroll(pos) {
            window.scrollTo({
                //滚动到元素位置
                //top: this.$refs[pos][0].offsetTop,    //offsetTop 相对于offsetparent 定位,当有定位时，不准确。
                //top: this.$refs[pos][0].getBoundingClientRect().top + window.scrollY,   //推荐使用。getBoundingClientRect 相对于当前视口的位置
                //top: 400  //滚动到指定距离
                top: pos,   //滚动到顶部
                //top: document.documentElement.scrollHeight,   //滚动到底部
                behavior: "smooth"  //平滑滚动
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.bor{
    width: 1200px;
    margin: 50px auto 80px;
}
.intr{
    width: 100%;
    img{
        width: 100%;
        height: 520px;
    }
    .text{
        width: 960px;
        margin: 30px auto 0;
        p{
            width: 100%;
            line-height: 21px;
            font-size: 16px;
            margin-top: 21px;
        }
        p:first-child{
            margin: 0;
        }
    }
}
.culture{
    width: 100%;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    li{
        width: 380px;
        height: 380px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
        }
        .count{
            display: none;
            width: 380px;
            height: 380px;
            background-color: rgba(0, 0, 0, .8);
            padding: 0 30px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            h1{
                width: 100%;
                line-height: 47px;
                text-align: center;
                font-size: 36px;
                font-weight: normal;
                color: #fff;
                margin-top: 139px;
            }
            p{
                width: 100%;
                line-height: 26px;
                text-align: center;
                font-size: 20px;
                color: #fff;
                margin-top: 30px;
            }
            p.count-p{
                display: block;
            }
            p.count-p-mob{
                display: none;
            }
        }
    }
    li:hover{
        .count{
            display: block;
        }
    }
}
.develop{
    width: 720px;
    margin: 50px auto 0;
    .main{
        width: 98px;
        min-height: 200px;
        border-right: 2px solid #00D3BE;
        padding-top: 100px;
        position: relative;
        .icon-top{
            display: block;
            width: 80px;
            height: 80px;
            border-radius: 80px;
            background-color: #00D3BE;
            position: absolute;
            top: 0;
            right: -40px;
            img{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        dl{
            width: 720px;
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            dt{
                width: 71px;
                position: relative;
                h3{
                    width: 71px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 30px;
                    font-weight: normal;
                    color: #00D3BE;
                    position: relative;
                    p{
                        width: 100%;
                        height: 26px;
                        line-height: 26px;
                        text-align: center;
                        font-size: 20px;
                        color: #00D3BE;
                        position: absolute;
                        left: 0;
                        bottom: -26px;
                    }
                }
                i{
                    display: block;
                    width: 16px;
                    height: 16px;
                    border-radius: 16px;
                    background-color: #00D3BE;
                    position: absolute;
                    top: 50%;
                    right: -36px;
                    transform: translateY(-50%);
                }
            }
            dd{
                width: 545px;
                min-height: 123px;
                border-radius: 8px;
                background-color: #00D3BE;
                padding: 30px;
                box-sizing: border-box;
                position: relative;
                p{
                    width: 100%;
                    line-height: 21px;
                    font-size: 16px;
                    color: #fff;
                }
                span{
                    display: block;
                    width: 38px;
                    height: 2px;
                    background-color: #00D3BE;
                    position: absolute;
                    top: 50%;
                    left: -38px;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
.til{
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px auto 0;
    position: relative;
    em{
        width: 160px;
        height: 1px;
        background-image: linear-gradient(to right, #fff , #9A9A9A);
    }
    i{
        width: 160px;
        height: 1px;
        background-image: linear-gradient(to right, #9A9A9A, #fff);
    }
    span{
        width: auto;
        padding: 0 10px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 30px;
        background-color: #fff;
    }
}
.connect{
    width: 100%;
    margin: 80px auto 0;
    padding: 30px 0 10px;
    background-color: #F4F6F8;
    p{
        width:1200px;
        line-height: 16px;
        margin: 0 auto 21px;
        display: flex;
        justify-content: center;
        i{
            display: block;
            width: 16px;
            height: 16px;
            margin-right: 5px;
            img{
                display: block;
                height: 16px;
                margin: 0 auto;
            }
        }
        span{
            display: block;
            width: 210px;
            line-height: 16px;
            line-height: 16px;
            font-size: 12px;
        }
    }
    p.b{
        span{
            width: 405px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .bor{
        width: 91.4%;
        margin: 24px auto 0;
    }
    .intr{
        width: 100%;
        img{
            width: 100%;
            height: auto;
        }
        .text{
            width: 100%;
            margin: 24px auto 0;
            p{
                width: 100%;
                line-height: 19px;
                font-size: 14px;
                margin-top: 19px;
            }
            p:first-child{
                margin: 0;
            }
        }
    }
    .culture{
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        li{
            width: 30%;
            height: auto;
            position: relative;
            img{
                width: 100%;
                height: auto;
            }
            .count{
                display: none;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .8);
                padding: 0 12px;
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                h1{
                    width: 100%;
                    line-height: 22px;
                    text-align: center;
                    font-size: 0.14rem;
                    font-weight: normal;
                    color: #fff;
                    margin-top: 0.12rem;
                }
                p{
                    width: 100%;
                    line-height: 0.12rem;
                    text-align: center;
                    font-size: 0.08rem;
                    color: #fff;
                    margin-top: 0.08rem;
                }
                p.count-p{
                    display: none;
                }
                p.count-p-mob{
                    display: block;
                }
            }
        }
        li:hover{
            .count{
                display: block;
            }
        }
    }
    .til{
        width: auto;
        height: auto;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 32px auto 0;
        position: relative;
        em{
            width: 36%;
            height: 1px;
            background-image: linear-gradient(to right, #fff , #9A9A9A);
        }
        i{
            width: 36%;
            height: 1px;
            background-image: linear-gradient(to right, #9A9A9A, #fff);
        }
        span{
            width: auto;
            padding: 0 2%;
            box-sizing: border-box;
            height: auto;
            line-height: 24px;
            text-align: center;
            font-size: 16px;
            background-color: #fff;
        }
    }
    .develop{
        width: 100%;
        margin: 24px auto 0;
        .main{
            width: 15.4%;
            min-height: 200px;
            border-right: 2px solid #00D3BE;
            padding-top: 80px;
            position: relative;
            .icon-top{
                display: block;
                width: 80px;
                height: auto;
                border-radius: 80px;
                background-color: #00D3BE;
                position: absolute;
                top: 0;
                right: -41px;
                img{
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
            dl{
                width: 649%;
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                dt{
                    width: 11%;
                    position: relative;
                    h3{
                        width: 100%;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                        font-size: 18px;
                        font-weight: normal;
                        color: #00D3BE;
                        position: relative;
                        p{
                            width: 100%;
                            height: 19px;
                            line-height: 19px;
                            text-align: center;
                            font-size: 14px;
                            color: #00D3BE;
                            position: absolute;
                            left: 0;
                            bottom: -19px;
                        }
                    }
                    i{
                        display: block;
                        width: 0.14rem;
                        height: 0.14rem;
                        border-radius: 0.14rem;
                        background-color: #00D3BE;
                        position: absolute;
                        top: 50%;
                        right: -62%;
                        transform: translateY(-50%);
                    }
                }
                dd{
                    width: 69.3%;
                    min-height: 112px;
                    border-radius: 8px;
                    background-color: #00D3BE;
                    padding: 16px;
                    box-sizing: border-box;
                    position: relative;
                    p{
                        width: 100%;
                        line-height: 16px;
                        font-size: 12px;
                        color: #fff;
                    }
                    span{
                        display: block;
                        width: 38px;
                        height: 2px;
                        background-color: #00D3BE;
                        position: absolute;
                        top: 50%;
                        left: -38px;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    .connect{
        width: 100%;
        margin: 32px auto 40px;
        padding: 32px 0;
        background-color: #F4F6F8;
        p{
            width: 100%;
            line-height: 19px;
            margin: 0 auto 32px;
            display: flex;
            justify-content: center;
            i{
                display: block;
                width: 19px;
                height: 19px;
                margin-right: 10px;
                img{
                    display: block;
                    height: 19px;
                    margin: 0 auto;
                }
            }
            span{
                display: block;
                width: 83%;
                line-height: 19px;
                font-size: 14px;
            }
        }
        p.b{
            margin-bottom: 0;
            span{
                width: 83%;
            }
        }
    }
}

@media screen and (max-width: 414px) {
    .culture{
        li{
            .count{
                h1{
                    line-height: 22px;
                    font-size: 0.14rem;
                    margin-top: 0.12rem;
                }
                p{
                    line-height: 0.12rem;
                    font-size: 0.08rem;
                    margin-top: 0;
                }
            }
        }
    }
}
</style>
