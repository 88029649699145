<template>
    <div class="home">
        <!-- <div class="top">{{mall}}</div> -->

        <MallSwiper class="bann" />

        <div class="main">
            <ul class="nav navs" :class="whether ? 'isFixed' : ''">
                <li @click='change(index)' :class='currentIndex == index ? "active" : ""' v-for='(item, index) in navs' :key='index'>{{item}}</li>
            </ul>

            <ul class="content">
                <li>
                    <div class="bor" v-if="listBor">
                        <a v-for='(item,index) in list' :key='index' :href="item.linkUrl">  <!-- @click="proCli(item.linkUrl)" -->
                            <div class="imgBor">
                                <img :src="item.imgUrl" alt="" />
                                <i v-if="item.discount !== 0"><img :src="iconImg" /><b>-{{item.discount}}%OFF</b></i>
                            </div>
                            <h3>{{item.type}}</h3>
                            <h5>{{item.remark}}</h5>
                            <p><b>{{item.moneyUnit}}{{item.discountPrice}}</b> <span v-if="item.discount !== 0">{{item.moneyUnit}}{{item.totalPrice}}</span></p>
                        </a>
                    </div>
                    <div class="null" v-if="proNull">
                        <img :src="nullImg" alt="提示">
                        <p>{{tips}}</p>
                    </div>
                </li>
                <div class="loading"  v-if="loading"></div>
            </ul>
        </div>
    </div>
</template>

<script>
//import { getAnalytics, logEvent } from "firebase/analytics";
import MallSwiper from '@/components/MallSwiper.vue';

export default {
    components: {
        MallSwiper
    },
    name: "Home",
    data() {
        return {
            listBor: true,
            loading: false,
            whether: false,
            proNull: false,
            adHeight: '',
            currentIndex: 0,
            iconImg: require('../assets/img/mall/icon3.png'),
            nullImg: require('../assets/img/mall/icon2.png'),
            country: 'US',
            category: '',
            mall: 'Mall',
            tips: '正在上货中，请稍后再来～',
            navs: [],
            list: [],
            query: {}
        }
    },
    mounted() {
        // const analytics = getAnalytics();
        // logEvent(analytics, 'view_mall', { page_title: 'view_mall'});

        // let w1 = document.querySelector('.w1')
        // querySelectorAll()   //所有元素
        // console.log(w1)

        window.addEventListener('scroll', this.handleScroll)

        let thisCode = this.getQuery('country_code');
        if(thisCode === ""){
            this.country = "US";
        } else{
            this.country = thisCode;
        }
        this.query = {country: this.country}
        if(thisCode === 'US'){
            //this.country = 'US'
            this.navs = ['All','Switches', 'Plugs', 'Lights']
            this.mall = 'Mall'
            this.tips = 'Loading, please come back later'
        } else if(thisCode === 'DE'){
            //this.country = 'DE'
            this.navs = ['Alle','Schalter', 'Steckdose', 'Erleuchtung']
            this.mall = 'Einkaufszentrum'
            this.tips = 'Wird geladen, bitte kommen Sie später wieder'
        } else if(thisCode === 'FR'){
            //this.country = 'FR'
            this.navs = ['Tout','Changer', 'Prise', 'Illumination']
            this.mall = 'Galerie marchande'
            this.tips = 'Chargement, veuillez revenir plus tard'
        } else if(thisCode === 'ES'){
            //this.country = 'ES'
            this.navs = ['Todos','Cambiar', 'Enchufe', 'Iluminación']
            this.mall = 'Centro comercial'
            this.tips = 'Cargando, vuelve más tarde'
        } else if(thisCode === 'IT'){
            //this.country = 'IT'
            this.navs = ['Tutto','Interruttore ', 'Presa', 'Illuminazione']
            this.mall = 'Centro commerciale'
            this.tips = 'Caricamento in corso, per favore torna più tardi'
        } else if(thisCode === 'CN'){
            //this.country = 'CN'
            this.navs = ['全部','开关 ', '插座', '照明']
            this.mall = '商城'
            this.tips = '正在上货中，请稍后再来～'
        } else if(thisCode === 'GB'){
            //this.country = 'GB'
            this.navs = ['All','Switches', 'Plugs', 'Lights']
            this.mall = 'Mall'
            this.tips = 'Loading, please come back later'
        } else{
            //this.country = 'US'
            this.navs = ['All','Switches', 'Plugs', 'Lights']
            this.mall = 'Mall'
            this.tips = 'Loading, please come back later'
        }

        this.getProduct()
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        // async proCli(url) {
        //     //console.log(url)
            
        //     try {
        //         let url_api = 'http://52.8.35.201:9091/uc-api/open-auth/auth-by-client'
        //         let clientId = '186067'
        //         let redirectUrl = url
        //         let token = "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb20uY3Vjby5zc28iLCJzdWIiOiJhY2Nlc3MtdG9rZW4iLCJhdWQiOiI1Mjg4NjM2NzZAcXEuY29tIiwianRpIjoiNzgwNjE5ODcwMzMyMDAyMzA0IiwiaWF0IjoxNjM3OTIwNDkxLCJleHAiOjE2Mzc5MjIyOTF9.avUImkepxzX2TGE1VAgy2_6hO_InNAt2WMnkD9SxEfk"

        //         await this.$axios.post(url_api,{
        //             clientId,
        //             redirectUrl
        //         },{
        //             transformRequest: [function (data) {
        //                 let ret = '';
        //                 //ret = $qs.stringify(data);
        //                 //注释方法是不使用插件
        //                 for (let it in data) {
        //                     ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
        //                     //ret += it + '=' + data[it] + '&';
        //                 }
        //                 return ret;
        //             }],
        //             headers: {
        //                 "Content-Type": "application/x-www-form-urlencoded",
        //                 "token": token
        //             }
        //         }).then(res => {
        //             console.log(res);
        //             console.log(res.result);
        //         })
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
        //监听页面滚动
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            let offsetTop = document.querySelector('.main').offsetTop
            if (scrollTop >= offsetTop) {
                this.whether = true
            } else {
                this.whether = false
            }
            //console.log(scrollTop,offsetTop)
        },
        //获取链接中的国家编码
        getQuery(strName){
            var strHref = document.location.toString();
            var intPos = strHref.indexOf("?");
            var strRight = strHref.substr(intPos + 1);
            var arrTmp = strRight.split("&");

            for (var i = 0; i < arrTmp.length; i++){
                var dIntPos = arrTmp[i].indexOf("=");
                var paraName = arrTmp[i].substr(0, dIntPos);
                var paraData = arrTmp[i].substr(dIntPos + 1);
                if (paraName.toUpperCase() == strName.toUpperCase()) {
                    return paraData;
                }
            }
            return "";
        },
        //点击切换tab
        change(index) {
            // if(index === 0){
            //     this.category = 'switches'
            // } else if(index === 1){
            //     this.category = 'plugs'
            // } else{
            //     this.category = 'lights'
            // }

            if(index === 0){
                this.query = {
                    country: this.country
                }
            } else if(index === 1){
                this.query = {
                    category: 'switches',
                    country: this.country
                }
            } else if(index === 2){
                this.query = {
                    category: 'plugs',
                    country: this.country
                }
            } else{
                this.query = {
                    category: 'lights',
                    country: this.country
                }
            }

            this.currentIndex = index

            this.listBor = false
            this.loading = true
            this.proNull = false
            
            this.getProduct()
        },
        //获取列表
        async getProduct(){
            try {
                let thisArea = await this.getQuery('country_area');
                //console.log(thisArea)

                let url_api = ''

                // if(thisArea === 'AZ'){
                //     url_api = 'https://test-uc-web.gosund.com/app-api/shop-product/list'
                // } else if(thisArea === 'EU'){
                //     url_api = 'https://test-uc-web.gosund.com/app-api/shop-product/list'
                // } else{
                //     url_api = 'https://test-uc-web.gosund.com/app-api/shop-product/list'
                // }

                if(thisArea === 'AZ'){
                    url_api = 'https://usapi.gosund.com/app-api/shop-product/list'
                } else if(thisArea === 'EU'){
                    url_api = 'https://euapi.gosund.com/app-api/shop-product/list'
                } else if(thisArea === 'AY'){
                    url_api = 'https://cnapi.gosund.com/app-api/shop-product/list'
                } else{
                    url_api = 'https://usapi.gosund.com/app-api/shop-product/list'
                }

                let params = this.query

                await this.$axios.get(url_api,{
                    params
                }).then(res => {
                    //console.log(res);
                    if(res.result.length === 0){
                        this.listBor = false
                        this.proNull = true
                        this.loading = false
                    } else{
                        this.list = res.result
                        this.listBor = true
                        this.proNull = false
                        this.loading = false
                    }
                })

                // await this.$http.get(this.$api.getPro.url, {
                //     category: this.category,
                //     country: this.country
                // })
                // .then((res) => {
                //     //console.log(res);
                //     if(res.result.length === 0){
                //         this.listBor = false
                //         this.proNull = true
                //         this.loading = false
                //     } else{
                //         this.list = res.result
                //         this.listBor = true
                //         this.proNull = false
                //         this.loading = false
                //     }
                // });
            } catch (error) {
                this.listBor = false
                this.proNull = true
                this.loading = false
                console.log(error)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
// .home{
//     padding-top: 44px;
// }
.top{
    width: 100%;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
    color: #2C302E;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
}
.null{
    width: 100%;
    text-align: center;
    img{
        display: block;
        width: 53.3%;
        margin: 0 auto;
    }
    p{
        width: 100%;
        text-align: center;
        font-size: 13px;
        color: #7D807F;
    }
}
.main{
    width: 100%;
    min-height: 60px;
    background-color: #F2F2F2;
    overflow: hidden;
    position: relative;
}
.nav{
    width: 100%;
    height: 64px;
    padding: 20px 3.2% 0;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    li{
        width: 22.93%;
        height: 44px;
        line-height: 33px;
        color: #2C302E;
        font-size: 15px;
        text-align: center;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
    li.active{
        color: #07C160;
        font-weight: 550;
        position: relative;
        &::before{
            content: '';
            display: block;
            width: 32.55%;
            height: 4px;
            border-radius: 5px;
            background-color: #07C160;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.navs{
    justify-content: space-evenly;
}
.content{
    width: 100%;
    padding: 80px 3.2% 0;
    box-sizing: border-box;
    background-color: #F2F2F2;
    position: relative;
    z-index: 9;
    .loading{
        width: 100%;
        min-height: 500px;
        position: absolute;
        top: 88px;
        left: 0;
        z-index: 999;
    }
    li{
        width: 100%;
        .bor{
            width: 100%;
            padding-bottom: 6px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            a{
                display: block;
                width: 48.5%;
                min-height: 50px;
                border-radius: 12px;
                background-color: #fff;
                margin-bottom: 12px;
                padding: 0 0 12px;
                box-shadow: 0 5px 16px 0 rgba($color: #000000, $alpha: 0.06);
                overflow: hidden;
                position: relative;
                .imgBor{
                    width: 100%;
                    position: relative;
                    img{
                        display: block;
                        width: 100%;
                    }
                    i{
                        display: block;
                        width: 48.23%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        img{
                            display: block;
                            width: 100%;
                        }
                        b{
                            display: block;
                            width: 100%;
                            height: 100%;
                            padding: 0 7.3%;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            font-style: normal;
                            font-size: 13px;
                            font-weight: bold;
                            color: #fff;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
                h3{
                    width: 100%;
                    padding: 0 7%;
                    box-sizing: border-box;
                    height: 18px;
                    line-height: 18px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #2C302E;
                    margin-top: 8px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow:ellipsis;
                }
                h5{
                    width: 100%;
                    padding: 0 7%;
                    box-sizing: border-box;
                    height: 31px;
                    line-height: 15px;
                    font-size: 12px;
                    font-weight: normal;
                    color: #989D9B;
                    margin-top: 4px;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                p{
                    width: 100%;
                    padding: 0 7%;
                    box-sizing: border-box;
                    height: 21px;
                    line-height: 21px;
                    margin-top: 8px;
                    color: #c8cccb;
                    // display: flex;
                    // align-items: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow:ellipsis;
                    b{
                        font-size: 18px;
                        font-weight: bold;
                        color: #F4992D;
                        display: inline-block;
                        vertical-align: bottom;
                    }
                    span{
                        height: 14px;
                        line-height: 14px;
                        font-size: 13px;
                        color: #C8CCCB;
                        text-decoration:line-through;
                        margin-left: 6px;
                    }
                }
            }
        }
    }
    li.current {
        overflow: hidden;
        overflow-y: auto;
    }
}

.isFixed {
    position: fixed!important;
    top: 0!important;
    left: 0!important;
    z-index: 99!important;
}
</style>
