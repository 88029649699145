<template>
    <div class="banner">
        <div class="bor">
            <div class="swiper-container swiper-container1">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <a>
                            <img src="../assets/img/banner/banner-1.png" alt="">
                            <div class="ban-txt-1"><img src="../assets/img/banner/banner-logo.png" alt="" /></div>
                            <div class="ban-txt-2">
                                <p>{{$t('home.lan-banner-1-1')}}</p>
                                <p>{{$t('home.lan-banner-1-2')}}</p>
                                <p>{{$t('home.lan-banner-1-3')}}</p>
                                <p>{{$t('home.lan-banner-1-4')}}</p>
                                <p>{{$t('home.lan-banner-1-5')}}</p>
                            </div>
                        </a>
                    </div>
                    <div class="swiper-slide">
                        <a>
                            <img src="../assets/img/banner/banner-2.png" alt="">
                            <div class="ban-txt-5">{{$t('home.lan-banner-4')}}</div>
                        </a>
                    </div>
                    <div class="swiper-slide">
                        <a>
                            <img src="../assets/img/banner/banner-3.png" alt="">
                            <div class="ban-txt-4" v-html="bannertxt3"></div>
                        </a>
                    </div>
                    <div class="swiper-slide">
                        <a>
                            <img src="../assets/img/banner/banner-4.png" alt="">
                            <div class="ban-txt-3">{{$t('home.lan-banner-2')}}</div>
                        </a>
                    </div>
                </div>
                
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
            </div>
            <!-- <div class="swiper-txt" v-if="swiperTxt">
                <div class="ban-txt-2" v-html="bannertxt1"></div>
            </div> -->
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";

export default {
    name: "Banner",
    props: {
        bannerLan: String
    },
    data() {
        return {
            swiperTxt: true,
            lanagerIs: 'zh',
            lanagerZh: 'zh',
            lanagerEn: 'en',
            bannertxt1: '',
            bannertxt3: "",
        }
    },
    mounted() {
        this.getSwiperZn()

        this.bannertxt1 = this.$t('home.lan-banner-1')
        this.bannertxt3 = this.$t('home.lan-banner-3')
    },
    methods: {
        async getSwiperZn(){
            try {
                let that = this
                await new Swiper(".swiper-container1", {
                    loop: true,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    },
                    on: {
                        slideChange: function(){
                            that.$cookie.get('lan')
                            if(this.activeIndex === 1 || this.activeIndex === 5){
                                setTimeout(() => {
                                    that.swiperTxt = true
                                }, 1);
                            } else{
                                setTimeout(() => {
                                    that.swiperTxt = false
                                }, 1);
                            }
                        },
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.bor {
    width: 100%;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    .swiper-container {
        width: 100%;
        //height: 720px;
        a {
            display: block;
            width: 100%;
            position: relative;
            img {
                display: block;
                width: 100%;
            }
            .ban-txt-1{
                width: 760px;
                height: 168px;
                position: absolute;
                top: 39%;
                left: 50%;
                transform: translateX(-50%);
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .ban-txt-2{
                width: 1200px;
                line-height: 26px;
                text-align: center;
                font-size: 20px;
                color: #fff;
                position: absolute;
                bottom: 66px;
                left: 50%;
                transform: translateX(-50%);
            }
            .ban-txt-3{
                width: 1200px;
                height: 158px;
                line-height: 158px;
                text-align: center;
                font-size: 100px;
                font-weight: bold;
                color: #fff;
                position: absolute;
                top: 25.18%;
                left: 50%;
                transform: translateX(-50%);
            }
            .ban-txt-4{
                width: 1200px;
                line-height: 92px;
                font-size: 70px;
                font-weight: bold;
                color: #fff;
                position: absolute;
                top: 32.96%;
                left: 50%;
                transform: translateX(-50%);
            }
            .ban-txt-5{
                width: 1200px;
                line-height: 158px;
                text-align: center;
                font-size: 100px;
                font-weight: bold;
                color: #fff;
                position: absolute;
                top: 24.66%;
                left: 50%;
                transform: translateX(-50%);
            }
            // p{
            //     color: #fff;
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            // }
        }
        .swiperCon{
            width: 1200px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .swiper-txt{
        width: 100%;
        height: 94%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        .ban-txt-2{
            width: 1200px;
            line-height: 26px;
            text-align: center;
            font-size: 20px;
            color: #fff;
            position: absolute;
            bottom: 1.8%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.swiper-slide{
    box-sizing: content-box;
    padding-bottom: 0.05rem;
    margin-top: -0.05rem;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    height: 15px;
    bottom: 30px;
    z-index: 9999;
}

@media screen and (max-width: 1200px) {
    .bor {
        min-width: auto;
        .swiper-container {
            a {
                .ban-txt-1{
                    width: 44.8%;
                    height: auto;
                    top: 31.4%;
                }
                .ban-txt-2{
                    width: 96%;
                    height: auto;
                    position: absolute;
                    bottom: 10%;
                    p{
                        width: 100%;
                        line-height: 0.1rem;
                        text-align: center;
                        font-size: 0.08rem;
                        color: #fff;
                        position: initial;
                    }
                }
                .ban-txt-3{
                    width: 96%;
                    height: 0.4rem;
                    line-height: 0.3rem;
                    font-size: 0.24rem;
                    font-weight: bold;
                    top: 19%;
                }
                .ban-txt-4{
                    width: 74%;
                    line-height: 0.26rem;
                    font-size: 0.2rem;
                    position: absolute;
                    top: 30.9%;
                }
                .ban-txt-5{
                    width: 96%;
                    line-height: 0.26rem;
                    font-size: 0.2rem;
                    position: absolute;
                    top: 15.2%;
                }
            }
        }
        .swiper-txt{
            width: 96%;
            height: auto;
            position: absolute;
            top: initial;
            bottom: 12%;
            left: 50%;
            transform: translateX(-50%);
            .ban-txt-2{
                width: 100%;
                line-height: 0.1rem;
                text-align: center;
                font-size: 0.08rem;
                color: #fff;
            }
        }
    }
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 4%;
    }
}
</style>
<style>
.swiper-pagination .swiper-pagination-bullet {
    width: 50px;
    height: 6px;
    border-radius: 3px;
    background-color: #fff;
    opacity: 0.5;
    margin: 0 10px !important;
    top: 0;
}
.swiper-pagination .swiper-pagination-bullet-active {
    width: 70px;
    background-color: #fff;
    opacity: 1;
}

@media screen and (max-width: 1200px) {
    .swiper-pagination .swiper-pagination-bullet {
        width: 0.06rem;
        height: 0.06rem;
        border-radius: 0.06rem;
        background-color: #fff;
        opacity: 0.5;
        margin: 0 0.05rem !important;
        top: 0;
    }
    .swiper-pagination .swiper-pagination-bullet-active {
        width: 0.06rem;
        background-color: #fff;
        opacity: 1;
    }
}
</style>
