import axios from "axios";

axios.defaults.timeout = 5000;
axios.defaults.baseURL = "/api";
//http://47.119.113.192:3000/mock/729
//http://47.119.113.192:3304

export default {
  /**
   * get 请求
   * @param url 接口路由
   * @returns {AxiosPromise<any>}
   */
  get(url, params, headers) {
    let options = {};

    if (params) {
      options.params = params;
    }

    if (headers) {
      options.headers = headers;
    }

    options.herders = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    return axios.get(url, options);
  },

  /**
   * post 请求
   *
   * @param url 接口路由
   * @param params 接口参数
   * @returns {AxiosPromise<any>}
   */
  post(url, headers, data) {
    let options = {};

    if (headers) {
      options.headers = headers;
    }

    options.herders = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    return axios.post(url, data, options);
  },
};
