<template>
    <div class="swiper">
        <div class="swiper-container"  v-if="swiperIs">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="adsbygoogle">
                        <!-- <Adsense
                            data-ad-client="ca-pub-5357414965179713"
                            data-ad-slot="2260924577"
                            data-ad-format="auto"
                            data-full-width-responsive="true"
                        >
                        </Adsense> -->
                    </div>
                </div>
                <div class="swiper-slide" v-for="(item, index) in data" :key="index">
                    <a :href="item.jumpUrl">
                        <img :src="item.bannerUrl" alt="" />
                    </a>
                </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
        </div>
        <div class="swiper-one" v-if="swiperIs !== true">
            <a :href="data[0].jumpUrl">
                <img :src="data[0].bannerUrl" alt="">
            </a>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";

export default {
    name: "Swiper",
    data() {
        return {
            country: 'US',
            swiperIs: true,
            data: []
        };
    },
    created() {
        // if (this.country_code === "US") {
        //     this.data = this.data_us;
        // } else if (this.country_code === "DE") {
        //     this.data = this.data_de;
        // } else {
        //     this.data = this.data_us;
        // }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getQuery(strName){
            var strHref = document.location.toString();
            var intPos = strHref.indexOf("?");
            var strRight = strHref.substr(intPos + 1);
            var arrTmp = strRight.split("&");

            for (var i = 0; i < arrTmp.length; i++){
                var dIntPos = arrTmp[i].indexOf("=");
                var paraName = arrTmp[i].substr(0, dIntPos);
                var paraData = arrTmp[i].substr(dIntPos + 1);
                if (paraName.toUpperCase() == strName.toUpperCase()) {
                    return paraData;
                }
            }
            return "";
        },
        async getData(){
            try {
                let thisCode = await this.getQuery('country_code');
                if(thisCode === ""){
                    this.country = "US";
                } else{
                    this.country = thisCode;
                }

                let thisArea = await this.getQuery('country_area');
                //console.log(thisCode,thisArea)

                let url_api = ''

                // if(thisArea === 'AZ'){
                //     url_api = 'https://test-uc-web.gosund.com/app-api/banner/list'
                // } else if(thisArea === 'EU'){
                //     url_api = 'https://test-uc-web.gosund.com/app-api/banner/list'
                // } else{
                //     url_api = 'https://test-uc-web.gosund.com/app-api/banner/list'
                // }

                if(thisArea === 'AZ'){
                    url_api = 'https://usapi.gosund.com/app-api/banner/list'
                } else if(thisArea === 'EU'){
                    url_api = 'https://euapi.gosund.com/app-api/banner/list'
                } else if(thisArea === 'AY'){
                    url_api = 'https://cnapi.gosund.com/app-api/banner/list'
                } else{
                    url_api = 'https://usapi.gosund.com/app-api/banner/list'
                }

                // await this.$http.get(this.$api.getBanner.url, {
                //     countryCode: thisCode,
                //     source: "shop",
                //     urlType: 0
                // })
                // .then((res) => {
                //     console.log(res);
                //     if(res.code === 200){
                //         if(res.result.length === 0){
                //             this.data = this.data_null
                //         } else{
                //             this.data = res.result
                //         }
                //     } else{
                //         this.data = res.result
                //         console.log(res.errmsg)
                //     }
                // })

                await this.$axios.get(url_api,{
                    params: {
                        countryCode: this.country,
                        source: "shop",
                        urlType: 0
                    },
                }).then(res => {
                    //console.log(res)
                    if(res.code === 200){
                        let leng = 0
                        if(res.result.length < 1){
                            return
                        } else if(res.result.length === 1){
                            //this.swiperIs = false
                            leng = 1
                        } else if(res.result.length > 5){
                            this.swiperIs = true
                            leng = 5
                        } else{
                            leng = res.result.length
                        }

                        for(let i=0;i<leng;i++){
                            this.data.push(res.result[i])
                        }
                    } else{
                        console.log(res.errmsg)
                    }
                })

                await new Swiper(".swiper-container", {
                    //loop: true,
                    pagination: {
                        el: ".swiper-pagination"
                    },
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false
                    },
                    observer: true,
                    observeParents: true
                })
            } catch (error) {
                this.data = this.data_null
                console.log(error)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.adsbygoogle{
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // position: absolute;
    // top: 0;
    // left: 0;
}

.swiper {
    width: 100%;
    background-color: #fff;
    .swiper-container {
        width: 100%;
    }
    .swiper-one{
        width: 100%;
        img{
            display: block;
            width: 100%;
        }
    }
    a {
        display: block;
        width: 100%;
        img {
            display: block;
            width: 100%;
        }
    }
}
.swiper-slide{
    // box-sizing: content-box;
    // padding-bottom: 0.05rem;
    // margin-top: -0.05rem;
    // transform: translate3d(0, 0, 0);
    // overflow: hidden;
    height: 1.6rem;
    position: relative;

    box-sizing: content-box;
    padding-left: 10px;
    margin-left: -10px;
    img {
        display: block;
        width: 100%;
    }
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    bottom: 8px;
}
</style>
<style>
/* .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 3px;
    border-radius: 6px;
    background-color: #ddd;
    opacity: 0.5;
    margin: 0 2px !important;
}
.swiper-pagination .swiper-pagination-bullet-active {
    background-color: rgba(7, 193, 96, 1);
    opacity: 1;
} */
</style>
