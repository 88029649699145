import Vue from "vue";
import i18n from './locales'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import qs from "qs";
import api from "./http/api";
import http from "./http/http";
import cookie from 'vue-cookie';
import Ads from 'vue-google-adsense';

import "./registerServiceWorker";
import "./http/axios";

Vue.config.productionTip = false;

Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;
Vue.prototype.$cookie = cookie;

// 对后端接口 进行全局注册，将api挂载到vue的原型上
Vue.prototype.$api = api;
// 对请求方式 进行全局注册
Vue.prototype.$http = http;

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)

new Vue({
    i18n,
    router,
    store,
    render: function (h) {
        return h(App);
    },
}).$mount("#app");
