<template>
    <div class="Footer">
        <div class="footer">
            <div class="main">
                <ul class="nav">
                    <li>
                        <h3><router-link to='/' @click.native="flushCom">{{$t('footer.lan-ind')}}</router-link></h3>
                    </li>
                    <li>
                        <h3><router-link :to="{path:'/product',query:{productType:'electrician'}}" @click.native="flushCom">{{$t('footer.lan-pro')}}</router-link></h3>
                        <p><router-link :to="{path:'/product',query:{productType:'electrician'}}" @click.native="flushCom">{{$t('footer.lan-electrician')}}</router-link></p>
                        <p><router-link :to="{path:'/product',query:{productType:'illumination'}}" @click.native="flushCom">{{$t('footer.lan-illumination')}}</router-link></p>
                        <p><router-link :to="{path:'/product',query:{productType:'accessories'}}" @click.native="flushCom">{{$t('footer.lan-accessories')}}</router-link></p>
                    </li>
                    <li>
                        <h3><router-link :to="{path:'/news',query:{newId:1}}" @click.native="flushCom">{{$t('footer.lan-news')}}</router-link></h3>
                        <p><router-link :to="{path:'/news',query:{newId:1}}" @click.native="flushCom">{{$t('footer.lan-newCompany')}}</router-link></p>
                        <p><router-link :to="{path:'/news',query:{newId:2}}" @click.native="flushCom">{{$t('footer.lan-newStaff')}}</router-link></p>
                    </li>
                    <li>
                        <h3><router-link :to="{path:'/supplier',query:{suppId:1}}" @click.native="flushCom">{{$t('footer.lan-supplier')}}</router-link></h3>
                    </li>
                    <li>
                        <h3><router-link :to="{path:'/about',query:{aboutId:1}}" @click.native="flushCom">{{$t('footer.lan-about')}}</router-link></h3>
                        <p><router-link :to="{path:'/about',query:{aboutId:1}}" @click.native="flushCom">{{$t('footer.lan-intr')}}</router-link></p>
                        <p><router-link :to="{path:'/about',query:{aboutId:2}}" @click.native="flushCom">{{$t('footer.lan-culture')}}</router-link></p>
                        <p><router-link :to="{path:'/about',query:{aboutId:3}}" @click.native="flushCom">{{$t('footer.lan-path')}}</router-link></p>
                        <p><router-link :to="{path:'/about',query:{aboutId:4}}" @click.native="flushCom">{{$t('footer.lan-connect')}}</router-link></p>
                    </li>
                </ul>
                <dl class="download-bor">
                    <dt>
                        <!-- <a href="##" target="_block"><img src="../assets/img/icon-dy.png" /></a>
                        <a href="##" target="_block"><img src="../assets/img/icon-ks.png" /></a>
                        <a href="##" target="_block"><img src="../assets/img/icon-wb.png" /></a> -->
                        <a class="hover-dy">
                            <i class="icon-dy"><img src="../assets/img/icon-dy.png" /></i>
                            <i class="icon-dy-h"><img src="../assets/img/icon-dy-h.png" /></i>
                            <em>
                                <img src="../assets/img/ewm-dy.png" />
                            </em>
                        </a>
                        <a class="hover-wx">
                            <i class="icon-wx"><img src="../assets/img/icon-wx.png" /></i>
                            <i class="icon-wx-h"><img src="../assets/img/icon-wx-h.png" /></i>
                            <em>
                                <img src="../assets/img/ewm.png" />
                            </em>
                        </a>
                    </dt>
                    <dd>
                        <!-- <div class="ewm-bor ewm-wx">
                            <img src="../assets/img/ewm.png" />
                        </div> -->
                    </dd>
                </dl>
            </div>
            <div class="bottom">
                <div class="count">
                    <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备2021051533号</a></p>
                    <p>{{$t('about.lan-address')}}：{{$t('about.lan-address-det')}} Copyright © 深圳市酷客智能科技有限公司</p>
                </div>
            </div>
        </div>

        <!-- 移动端 -->
        <div class="mob-footer">
            <div class="mob-fot-main">
                <ul class="mob-fot-nav">
                    <li>
                        <router-link :to="{path:'/'}" @click.native="refresh">{{$t('top.lan-index')}}</router-link>
                    </li>
                    <li @click.stop="fotPro = !fotPro">
                        <span>{{$t('top.lan-pro')}}</span>
                        <i v-show="fotPro === true"><img src="../assets/img/icon-right.png" /></i>
                        <i v-show="fotPro === false"><img src="../assets/img/icon-down.png" /></i>
                    </li>
                    <div class="mob-fotNavs-con" v-show="fotPro === false">
                        <p>
                            <router-link :to="{path:'/product',query:{productType:'electrician'}}" @click.native="refresh">{{$t('home.lan-electrician')}}</router-link>
                        </p>
                        <p>
                            <router-link :to="{path:'/product',query:{productType:'illumination'}}" @click.native="refresh">{{$t('home.lan-illumination')}}</router-link>
                        </p>
                        <p>
                            <router-link :to="{path:'/product',query:{productType:'accessories'}}" @click.native="refresh">{{$t('home.lan-accessories')}}</router-link>
                        </p>
                    </div>
                    <li @click.stop="fotNews = !fotNews">
                        <span>{{$t('top.lan-news')}}</span>
                        <i v-show="fotNews === true"><img src="../assets/img/icon-right.png" /></i>
                        <i v-show="fotNews === false"><img src="../assets/img/icon-down.png" /></i>
                    </li>
                    <div class="mob-fotNavs-con" v-show="fotNews === false">
                        <p>
                            <router-link :to="{path:'/news',query:{newId:1}}" @click.native="refresh">{{$t('top.lan-company')}}</router-link>
                        </p>
                        <p>
                            <router-link :to="{path:'/news',query:{newId:2}}" @click.native="refresh">{{$t('top.lan-staff')}}</router-link>
                        </p>
                    </div>
                    <li>
                        <router-link :to="{path:'/supplier'}" @click.native="refresh">{{$t('top.lan-suppier')}}</router-link>
                    </li>
                    <li @click.stop="fotAbout = !fotAbout">
                        <span>{{$t('top.lan-about')}}</span>
                        <i v-show="fotAbout === true"><img src="../assets/img/icon-right.png" /></i>
                        <i v-show="fotAbout === false"><img src="../assets/img/icon-down.png" /></i>
                    </li>
                    <div class="mob-fotNavs-con" v-show="fotAbout === false">
                        <p>
                            <router-link :to="{path:'/about',query:{aboutId:1}}" @click.native="refresh">{{$t('top.lan-intr')}}</router-link>
                        </p>
                        <p>
                            <router-link :to="{path:'/about',query:{aboutId:2}}" @click.native="refresh">{{$t('top.lan-culture')}}</router-link>
                        </p>
                        <p>
                            <router-link :to="{path:'/about',query:{aboutId:3}}" @click.native="refresh">{{$t('top.lan-develop')}}</router-link>
                        </p>
                        <p>
                            <router-link :to="{path:'/about',query:{aboutId:4}}" @click.native="refresh">{{$t('top.lan-connect')}}</router-link>
                        </p>
                    </div>
                </ul>
                
                <ul class="mob-fot-icon">
                    <li>
                        <img class="mob-fot-icon-img" src="../assets/img/icon-dy.png" />
                        <em>
                            <img src="../assets/img/ewm-dy.png" />
                        </em>
                    </li>
                    <li>
                        <img class="mob-fot-icon-img" src="../assets/img/icon-wx.png" />
                        <em>
                            <img src="../assets/img/ewm.png" />
                        </em>
                    </li>
                </ul>
            </div>
            <div class="mob-fot-bot">
                <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备2021051533号</a></p>
                <p>{{$t('about.lan-address')}}：{{$t('about.lan-address-det')}} Copyright © 深圳市酷客智能科技有限公司</p>
            </div>
        </div>

        <!-- 返回顶部 -->
        <a class="tops" @click="backToTop" v-show="topIs"><img src="../assets/img/icon-top.png" /></a>
    </div>
</template>

<script>
export default {
    name: "Footer",
    inject: ['reload'],
    data() {
        return {
            topIs: false,
            ewmWx: false,
            mobNav: false,
            fotPro: true,
            fotNews: true,
            fotAbout: true
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        backToTop() {
            //window.scrollTo(0,0)
            window.scrollTo({top: 0, behavior: "smooth"});
        },
        //路由刷新
        flushCom() {
            this.$router.go(0)
        },
        //监听页面滚动
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if (scrollTop > 500) {
                this.topIs = true
            } else {
                this.topIs = false
            }
            //console.log(scrollTop)
        },
        //路由刷新
        refresh() {
            //this.$router.go(0)
            this.reload()
        }
    }
};
</script>

<style lang="scss" scoped>
.footer{
    width: 100%;
    background-color: #F4F6F8;
    padding: 60px 0 0;
}
.main{
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 40px;
    display: flex;
    .nav{
        margin-right: 100px;
        display: flex;
        li{
            width: auto;
            margin-right: 80px;
            h3{
                width: 100%;
                height: 16px;
                line-height: 16px;
                font-size: 14px;
                color: #000;
                margin-bottom: 20px;
                a:hover{
                    cursor: pointer;
                    color: #00D3BE;
                }
            }
            p{
                width: 100%;
                height: 16px;
                line-height: 16px;
                font-size: 12px;
                margin-bottom: 20px;
                a:hover{
                    cursor: pointer;
                    color: #00D3BE;
                }
            }
        }
    }
}
.download-bor{
    dt{
        display: flex;
        a{
            display: block;
            width: 38px;
            height: 38px;
            margin-right: 40px;
            position: relative;
            i{
                display: block;
                width: 100%;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            i.icon-dy{
                display: block;
            }
            i.icon-dy-h{
                display: none;
            }
            i.icon-wx{
                display: block;
            }
            i.icon-wx-h{
                display: none;
            }
            em{
                display: none;
                width: 120px;
                height: 120px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        a.hover-dy:hover{
            cursor: pointer;
            i.icon-dy{
                display: none;
            }
            i.icon-dy-h{
                display: block;
            }
            em{
                display: block;
            }
        }
        a.hover-wx:hover{
            cursor: pointer;
            i.icon-wx{
                display: none;
            }
            i.icon-wx-h{
                display: block;
            }
            em{
                display: block;
            }
        }
    }
    dd{
        margin-top: 8px;
        .ewm-bor{
            width: 120px;
            height: 120px;
            img{
                width: 100%;
            }
        }
        .download{
            display: flex;
            li{
                width: 100px;
                margin-right: 30px;
                img{
                    width: 100%;
                }
                p{
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i{
                        display: block;
                        width: 16px;
                        height: 16px;
                        margin-right: 10px;
                        img{
                            width: 100%;
                        }
                    }
                    span{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
.tops{
    display: block;
    width: 40px;
    height: 40px;
    margin-top: 40px;
    margin-left: auto;
    position: fixed;
    right: 10px;
    bottom: 140px;
    &:hover{
        cursor: pointer;
        opacity: 0.8;
    }
    img{
        width: 100%;
    }
}
.bottom{
    width: 100%;
    background-color: #242424;
    .count{
        width: 1200px;
        height: 60px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
            font-size: 12px;
            color: #fff;
            a{
                color: #fff;
            }
        }
    }
}

.mob-footer{
    display: none;
    width: 100%;
    .mob-fot-main{
        width: 100%;
        background-color: #F4F6F8;
        padding: 40px 4.3%;
        box-sizing: border-box;
        .mob-fot-nav{
            width: 100%;
            li{
                width: 100%;
                height: 22px;
                line-height: 22px;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                a{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                i{
                    display: block;
                    width: 16px;
                    height: 16px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .mob-fotNavs-con{
                width: 100%;
                margin-top: -8px;
                p{
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 14px;
                    margin-bottom: 24px;
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
                p:last-child{
                    margin-bottom: 32px;
                }
            }
        }
        .mob-fot-icon{
            width: 100%;
            margin-top: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            li{
                width: 38px;
                margin-right: 82px;
                position: relative;
                .mob-fot-icon-img{
                    width: 100%;
                }
                em{
                    display: none;
                    width: 120px;
                    height: 120px;
                    position: absolute;
                    top: -120px;
                    left: 50%;
                    transform: translateX(-50%);
                    img{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            li:hover{
                em{
                    display: block;
                }
            }
            li:last-child{
                margin: 0;
            }
        }
    }
    .mob-fot-bot{
        width: 100%;
        padding: 16px 4.3%;
        box-sizing: border-box;
        background-color: #242424;
        p{
            width: 100%;
            line-height: 14px;
            font-size: 10px;
            color: #fff;
            text-align: center;
            margin-bottom: 8px;
            a{
                color: #fff;
            }
        }
        p:last-child{
            margin: 0;
        }
    }
}

@media screen and (max-width: 1200px) {
    .footer{
        display: none;
    }
    .mob-footer{
        display: block;
    }
    .tops{
        display: block;
        width: 40px;
        height: 40px;
        position: fixed;
        right: 4.3%;
        bottom: 128px;
        &:hover{
            cursor: pointer;
            opacity: 0.8;
        }
        img{
            width: 100%;
        }
    }
}
</style>
