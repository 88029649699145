<template>
    <div id="app">
        <router-view v-if="isRouterAlive" />
    </div>
</template>

<script>
export default {
    name: "app",
    provide () {
        return {
            reload: this.reload
        }
    },
    data() {
        return {
            isRouterAlive: true
        }
    },
    methods: {
        reload () {
            this.isRouterAlive = false
            this.$nextTick(function() {
                this.isRouterAlive = true
            })
        }
    }
};
</script>

<style lang="scss">
* {
    margin: 0;padding: 0;-webkit-tap-highlight-color: rgba(0, 0, 0, 0);-webkit-tap-highlight-color: transparent;
}
body {
    font-family: PingFangSC-Regular, sans-serif, "Microsoft YaHei", Arial, Tahoma, sans-serif;
    color: #333;
    font-size: 14px;
    padding-top: 70px;
}
a {
    color: #333;
    text-decoration: none;
    font-family: PingFangSC-Regular, sans-serif, "Microsoft YaHei", Arial, Tahoma, sans-serif;
}
a:hover {
    text-decoration: none;
}
ul,li {
    list-style: none;
}
h1,h2,h3,h4,h5,h6 {
    font-size: 100%;
}
input, select, textarea{
    outline: none;
}
img{
    display: block!important;
}

@media screen and (max-width: 1040px) {
    body{
        padding-top: 44px;
    }
}
</style>
