<template>
    <div class="news">
        <Top :current="currentIndex" />

        <div class="bor"  v-if="borIs === '1'">
            <div class="top-news">
                <a href="https://baijiahao.baidu.com/s?id=1709689190069603535&wfr=spider&for=pc" target="_block">
                    <img src="../assets/img/news-1.png" alt="">
                    <div class="main">
                        <h3>另辟蹊径抢占海外市场!酷客智能崛起靠的是速度为先</h3>
                        <p class="tips">2021-09-01</p>
                        <div class="count">从智能音箱、智能电视、智能门锁，到扫地机器人，“智能”开始全面进入我们的生活。根据市场调研机构CSHIA的预计，到2023年，全球智能家居市场规模将增长至1570亿美元。而中国作为制造大国，家电、家居和互联网三大产业相辅相成，已经构成完整的智能家居产业链。</div>
                        <p class="bot">查看详情 >></p>
                    </div>
                </a>
            </div>
            <ul class="main">
                <li>
                    <a href="https://www.sohu.com/a/424098442_120019324" target="_block">
                        <img src="../assets/img/news-2.png" alt="">
                        <div class="count">
                            <h3>酷客智能受邀参加第八届投融会</h3>
                            <p>10月9日，第八届中国中小企业融资交易会暨2020“小企业 大梦想”高峰论坛在北京隆重开幕。作为出海智能家居代表企业，酷客智能受邀参加大会，现场展示了Gosund智能电工、NiteBird智能照明和Goowls智能安防三大品牌产品，并通过智能家居模型进行生动的场景演示，获得众多与会嘉宾的认可。</p>
                            <span>2021年3月26日</span>
                        </div>
                        <div class="time">
                            <div class="time-count">
                                <h3>26</h3>
                                <p>2021-03</p>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="http://www.iheima.com/article-313408.html" target="_block">
                        <img src="../assets/img/news-3.png" alt="">
                        <div class="count">
                            <h3>酷客智能连续3年增长100%的背后，智能家居加速狂奔！</h3>
                            <p>从最早一个智能音箱、一把智能锁开始,随着5G的加速,疫情的倒逼,智能家居成为全球最为需求迫切的物联网应用场景之一。根据权威调研机构Strategy Analytics最新发布的报告指出:2021年全球智能家居市场规模规模将达到620亿美元,较2020年同比增长40.9%。</p>
                            <span>2021年3月9日</span>
                        </div>
                        <div class="time">
                            <div class="time-count">
                                <h3>09</h3>
                                <p>2021-03</p>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="http://mil.qianlong.com/2021/0225/5452499.shtml" target="_block">
                        <img src="../assets/img/news-4.png" alt="">
                        <div class="count">
                            <h3>中国中小企业协会会长李子彬一行莅临酷客智能参观指导</h3>
                            <p>2月20日，中国中小企业协会会长李子彬一行莅临酷客智能参观访问，深圳市酷客智能科技有限公司（以下简称“酷客智能”）总经理陈才携公司核心高管热情接待。宝安区有关部门领导参加了相关活动。</p>
                            <span>2021年2月25日</span>
                        </div>
                        <div class="time">
                            <div class="time-count">
                                <h3>25</h3>
                                <p>2021-02</p>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://baijiahao.baidu.com/s?id=1662854567299719043&wfr=spider&for=pc" target="_block">
                        <img src="../assets/img/news-5.png" alt="">
                        <div class="count">
                            <h3>酷客智能全面启动两化融合管理体系贯标</h3>
                            <p>2020年3月28日下午，深圳市酷客智能科技有限公司两化融合管理体系贯标启动会在公司办公楼培训室隆重举行。公司总经理陈才先生携各部门经理及贯标咨询服务机构中科创服顾问团队一同出席会议，共同探讨两化融合管理体系贯标工作方案。</p>
                            <span>2021年4月21日</span>
                        </div>
                        <div class="time">
                            <div class="time-count">
                                <h3>21</h3>
                                <p>2021-04</p>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
            <div class="page" v-if="pageIs">

            </div>
        </div>

        <div class="bor" v-if="borIs === '2'">
            <ul class="staff">
                <li>
                    <img src="../assets/img/icon-cp.png" alt="" />
                    <p>{{$t('news.lan-news-1')}}</p>
                </li>
                <li>
                    <img src="../assets/img/icon-qq.png" alt="" />
                    <p>{{$t('news.lan-news-2')}}</p>
                </li>
                <li>
                    <img src="../assets/img/icon-lz.png" alt="" />
                    <p>{{$t('news.lan-news-3')}}</p>
                </li>
            </ul>
        </div>
        <!-- <p>{{borIs}}</p> -->

        <Footer />
    </div>
</template>

<script>
import Top from '@/components/Top.vue'
import Footer from "@/components/Footer.vue"

export default {
    components: {
        Top,
        Footer
    },
    name: "News",
    data() {
        return {
            currentIndex: 2,
            pageIs: true,
            borIs: '1'
        }
    },
    mounted() {
        let id = this.$route.query.newId;
        this.borIs = id
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
.bor{
    width: 1200px;
    margin: 50px auto 0;
}
.top-news{
    width: 100%;
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 40px;
    padding-bottom: 20px;
    a{
        display: block;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        &:hover{
            background-color: #FAFAFA;
            .main p.bot{
                color: #00D3BE;
            }
        }
        img{
            display: block;
            width: 590px;
            height: 354px;
            margin: 0 20px 0 0;
        }
        .main{
            width: 560px;
            h3{
                width: 100%;
                line-height: 24px;
                font-size: 18px;
                color: #333;
                margin-bottom: 10px;
            }
            p.tips{
                width: 100%;
                line-height: 21px;
                font-size: 14px;
                color: #9A9A9A;
                margin-bottom: 16px;
            }
            .count{
                width: 100%;
                min-height: 239px;
                line-height: 30px;
                font-size: 16px;
                color: #333;
                margin-bottom: 20px;
            }
            p.bot{
                width: 100%;
                line-height: 24px;
                font-size: 18px;
                color: #333;
            }
        }
    }
}
.main{
    width: 100%;
    li{
        width: 100%;
        border-bottom: 1px solid #D8D8D8;
        margin-bottom: 40px;
        padding-bottom: 20px;
        a{
            display: block;
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 8px;
            display: flex;
            &:hover{
                background-color: #FAFAFA;
            }
            img{
                display: block;
                width: 200px;
                height: 120px;
                margin: 0 20px 0 0;
            }
            .count{
                width: 730px;
                margin-right: 80px;
                h3{
                    width: 100%;
                    line-height: 21px;
                    font-size: 16px;
                    margin-bottom: 20px;
                }
                p{
                    width: 100%;
                    line-height: 16px;
                    font-size: 14px;
                }
                span{
                    display: none;
                }
            }
            .time{
                width: 150px;
                height: 80px;
                margin-top: 20px;
                border-left: 1px solid #D8D8D8;
                padding-right: 10px;
                box-sizing: border-box;
                position: relative;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .time-count{
                    width: 60px;
                    h3{
                        width: 100%;
                        line-height: 31px;
                        text-align: center;
                        font-size: 24px;
                        font-weight: normal;
                    }
                    p{
                        width: 100%;
                        line-height: 16px;
                        font-size: 12px;
                        text-align: center;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
.page{
    width: 1200px;
    height: 28px;
    margin: 0 auto 80px;
}

.staff{
    width: 100%;
    margin-bottom: 50px;
    li{
        width: 100%;
        margin-bottom: 30px;
        position: relative;
        img{
            width: 100%;
            height: 460px;
        }
        p{
            width: 100%;
            height: 26px;
            line-height: 26px;
            text-align: center;
            font-size: 20px;
            color: #fff;
            position: absolute;
            left: 0;
            bottom: 30px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .bor{
        width: 91.4%;
        margin: 24px auto 0;
    }
    .top-news{
        margin-bottom: 32px;
        padding-bottom: 16px;
        a{
            padding: 0;
            display: initial;
            img{
                width: 100%;
                height: auto;
                margin: 0;
            }
            .main{
                width: 100%;
                margin-top: 24px;
                h3{
                    margin-bottom: 8px;
                }
                p.tips{
                    line-height: 16px;
                    font-size: 12px;
                    margin-bottom: 0;
                }
                .count{
                    width: 100%;
                    min-height: auto;
                    line-height: 19px;
                    font-size: 14px;
                    color: #333;
                    margin-bottom: 0;
                    margin-top: 16px;
                }
                p.bot{
                    display: none;
                }
            }
        }
    }
    .main{
        width: 100%;
        li{
            margin-bottom: 32px;
            padding-bottom: 16px;
            a{
                padding: 0;
                box-sizing: border-box;
                border-radius: 8px;
                display: flex;
                &:hover{
                    background-color: #fff;
                }
                img{
                    width: 31.4%;
                    height: auto;
                    margin: 0 4% 0 0;
                }
                .count{
                    width: 64.6%;
                    margin-right: 0;
                    position: relative;
                    h3{
                        width: 100%;
                        line-height: 19px;
                        font-size: 14px;
                        margin-bottom: 20px;
                    }
                    p{
                        display: none;
                    }
                    span{
                        display: block;
                        width: 100%;
                        line-height: 16px;
                        font-size: 12px;
                        color: #9A9A9A;
                        text-align: right;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }
                .time{
                    display: none;
                }
            }
        }
    }
    .staff{
        margin-bottom: 40px;
        li{
            width: 100%;
            margin-bottom: 32px;
            position: relative;
            img{
                width: 100%;
                height: auto;
            }
            p{
                width: 100%;
                height: 19px;
                line-height: 19px;
                text-align: center;
                font-size: 14px;
                color: #333;
                position: initial;
                margin-top: 16px;
            }
        }
    }
    .page{
        display: none;
    }
}
</style>
