module.exports = {
    top: {
        "lan-zh": "中文",
        "lan-en": "English",
        "lan-index": "首页",
        "lan-pro": "产品",
        "lan-news": "新闻动态",
        "lan-suppier": "加入我们",
        "lan-about": "关于",
        "lan-company": "公司新闻",
        "lan-staff": "员工活动",
        "lan-intr": "公司简介",
        "lan-culture": "企业文化",
        "lan-develop": "发展历程",
        "lan-connect": "联系我们"
    },
    footer: {
        "lan-ind": "首页",
        "lan-pro": "产品",
        "lan-electrician": "电工",
        "lan-illumination": "照明",
        "lan-accessories": "数码配件",
        "lan-app": "APP下载",
        "lan-news": "新闻动态",
        "lan-newCompany": "公司新闻",
        "lan-newStaff": "员工活动",
        "lan-supplier": "加入我们",
        "lan-about": "关于",
        "lan-intr": "公司介绍",
        "lan-culture": "企业文化",
        "lan-path": "发展历程",
        "lan-connect": "联系我们",
        "lan-icp": "粤ICP备"
    },
    home: {
        "lan-banner-1": "电小酷是深圳市酷客智能科技有限公司（简称“酷客智能”）旗下的新创品牌。<br />产品涵盖：快充插头、快充线、移动电源、插座等系列，<br />电小酷品牌所有产品技术均出酷客智能研发中心开发、生产，<br />致力为用户打造更好用的产品，更舒适的产品体验。",
        "lan-banner-1-1": "电小酷是深圳市酷客智能科技有限公司（简称“酷客智能”）旗下的新创品牌。",
        "lan-banner-1-2": "产品涵盖：快充插头、快充线、移动电源、插座等系列，",
        "lan-banner-1-3": "电小酷品牌所有产品技术均出酷客智能研发中心开发、生产，",
        "lan-banner-1-4": "致力为用户打造更好用的产品，更舒适的产品体验。",
        "lan-banner-1-5": "",
        "lan-banner-2": "速度快，就是酷！",
        "lan-banner-3": "流光溢彩，<br />尽在掌握之中",
        "lan-banner-4": "未来，触手可及",
        "lan-electrician": "电工",
        "lan-illumination": "照明",
        "lan-accessories": "数码配件",
        "lan-cp1-1": "电小酷",
        "lan-cp1-2": "智能插座CP1",
        "lan-cp1-3": "打开你的智能生活",
        "lan-cp5": "电小酷<br />能排插CP5 PRO",
        "lan-cp5-1": "电小酷",
        "lan-cp5-2": "能排插CP5 PRO",
        "lan-cp5-3": "65W氮化镓快充排插",
        "lan-20": "20W快充插头",
        "lan-20-1": "20W快充插头",
        "lan-20-2": "4倍提速，岂止是快",
        "lan-65": "65W适配器",
        "lan-65-1": "65W适配器",
        "lan-65-2": "双TYPE-C口，能量分配功能",
        "lan-light": "灯光设计",
        "lan-light-1": "可调光智能灯",
        "lan-light-2": "为你的居家生活灯光带来更多色彩"
    },
    product: {
        "lan-more": "更多信息",
        "lan-product": "产品",
        "lan-dowmload": "下载资料包",
        "lan-goBuy": "去购买",
        "lan-ratedPower": "额定功率",
        "lan-operatingTemperature": "工作温度",
        "lan-productSize": "产品尺寸",
        "lan-color": "颜色",
        "lan-lightColor": "灯光颜色",
        "lan-wirelessType": "无线类型",
        "lan-fireLevel": "防火等级",
        "lan-enter": "输入",
        "lan-lumen": "流明",
        "lan-materials": "材质",
        "lan-connector": "电源接口",
        "lan-screw": "螺口",
        "lan-temperature": "色温值",
        "lan-color-w": "白色",
        "lan-color-b": "黑色",
        "lan-material": "PC（V0 防火）",
        "lan-name-1": "电小酷智能插座CP1",
        "lan-name-2": "电小酷智能插座CP1-AM（记电量版）",
        "lan-name-3": "电小酷智能插座CP2（记电量版）",
        "lan-name-4": "电小酷智能一转多转换插座CP2-AM",
        "lan-name-5": "电小酷空调伴侣CP3（未上线）",
        "lan-name-6": "电小酷智能一转多转换插座CP3-AM",
        "lan-name-7": "电小酷智能排插CP5",
        "lan-name-8": "电小酷智能排插CP5-B",
        "lan-name-9": "电小酷氮化镓智能排插CP5 PRO",
        "lan-name-10": "电小酷智能墙壁开关CS1（零火单线版）",
        "lan-name-11": "电小酷智能墙壁开关CS2（零火双线版）",
        "lan-name-12": "电小酷智能墙壁开关CS3（零火三线版）",
        "lan-name-13": "电小酷智能墙壁开关S4AM（单火单线版）",
        "lan-name-14": "电小酷智能墙壁开关S5AM（单火双线版）",
        "lan-name-15": "电小酷智能墙壁开关S6AM（单火三线版）",
        "lan-name-16": "电小酷智能彩色床头灯LB3",
        "lan-name-17": "电小酷智能星空投影仪LB4",
        "lan-name-18": "电小酷智能灯泡WB2",
        "lan-name-19": "电小酷智能灯泡WB5（彩色版）",
        "lan-name-20": "电小酷20W快充充电头N1A（彩色版）",
        "lan-tips-1": "接入米家APP / 小爱同学语音控制 / 本地定时 / 智能联动 / 断电记忆",
        "lan-tips-2": "接入米家APP / 小爱同学语音控制 / 电量统计 / 定时开关 / 智能联动",
        "lan-tips-3": "接入米家APP / 小爱同学语音控制 / 电量统计 / 定时开关 / 智能联动",
        "lan-tips-4": "接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 断电记忆",
        "lan-tips-5": "接入米家APP / 小爱同学语音控制",
        "lan-tips-6": "接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 断电记忆",
        "lan-tips-7": "18W快充 / QC3.0 / 接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 4位独立分控",
        "lan-tips-8": "18W快充 / QC3.0 / 接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 /  4位独立分控",
        "lan-tips-9": "65W快充 / 氮化镓快充技术 / 接入米家APP / 小爱同学语音分控 / 本地定时 / 智能联动 / 4位独立分控",
        "lan-tips-10": "标准86底盒 / 接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 断电记忆",
        "lan-tips-11": "标准86底盒 / 接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 断电记忆",
        "lan-tips-12": "标准86底盒 / 接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 断电记忆",
        "lan-tips-13": "标准86底盒 / 接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 断电记忆",
        "lan-tips-14": "标准86底盒 / 接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 断电记忆",
        "lan-tips-15": "标准86底盒 / 接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 断电记忆",
        "lan-tips-16": "接入涂鸦APP / 语音控制 / 轻拍开关 / 定时开关 / 音乐律动 / 计划模式",
        "lan-tips-17": "接入涂鸦APP / 语音控制 / 定时开关 / 音乐律动 / 360°动态投影 / 4种效果模式",
        "lan-tips-18": "E27通用螺口 / 接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 情景模式 / 断电记忆",
        "lan-tips-19": "E27通用螺口 / 接入米家APP / 小爱同学语音控制 / 定时开关 / 智能联动 / 情景模式 / 断电记忆",
        "lan-tips-20": "20W大功率 / 400%功率提速 / 快充不烫手 / mini身材 / 涓流充电 / DXK PI技术 / 三年只换不修"
    },
    news: {
        "lan-news-1": "员工集中培训，晨跑",
        "lan-news-2": "素质拓展活动，团队协作",
        "lan-news-3": "端午节活动，赛龙舟"
    },
    supplier: {
        "lan-img-intr-1": "全球招商加盟",
        "lan-img-intr-2": "智能家居万亿级市场发展",
        "lan-img-intr-3": "电小酷超级合伙人招募",
        "lan-img-intr-4": "电商渠道分销 | 线下渠道合作 | IOT领域先行者",
        "lan-img-intr-5": "市场前景介绍",
        "lan-img-intr-6": "根据Statista数据测算，2020年智能家居市场规模约为4345亿元，2025年预计突破8000亿元，符合增速15.8%。从中国个细分市场规模看，智能家电原始市场规模大，智能化发展早其渗透率高，智能家电占比最大，收入为2822亿元；",
        "lan-til-1": "电小酷优势",
        "lan-til-2": "联系方式",
        "lan-adv-1": "2017年正式成立，是一家专注于IOT领域，集智能电工、智能照明、智能安防、智能小家电、智能配件等硬件产品研发、生产、销售和服务于一体的国家级高新技术企业。",
        "lan-adv-2": "2018年通过ISO9001质量管理体系认证，年销售额过亿，亚马逊垂直类目销售冠军。",
        "lan-adv-3": "2019年年销售增长100%，建立洛杉矶分公司，成立德国和美国售后服务中心，业务覆盖全球31个国家和地区，智造基地升级为15000平方米，月均产能100W以上。",
        "lan-adv-4": "2020年全球设备累计激活千万，年营业额再次100%增长，获得4项红点产品设计大奖。",
        "lan-name": "姓名",
        "lan-pho": "联系方式",
        "lan-name-err": "请输入姓名",
        "lan-pho-err": "请输入联系方式",
        "lan-sure": "确定",
        "lan-suc": "信息提交成功"
    },
    about: {
        "lan-intr-1": "电小酷是深圳市酷客智能科技有限公司（简称“酷客智能”）于2021年成立的子品牌，针对国内市场而生，专注于智能小家电、智能配件等硬件产品研发、生产、销售和服务于一体的品牌。",
        "lan-intr-2": "“酷客智能”于2017年正式成立，是一家专注于IoT领域，集智能电工、智能照明、智能安防、智能小家电等硬件产品研发、生产、销售和服务于一体的国家级高新技术企业。",
        "lan-mission": "使命",
        "lan-vision": "愿景",
        "lan-values": "价值观",
        "lan-mission-det": "为全球用户打造智能舒适的生活空间",
        "lan-vision-det": "到2030年服务全球1亿家庭",
        "lan-values-det": "以用户为中心，拥抱变化<br />极致高效，担当可依赖",
        "lan-values-det-mob": "以用户为中心<br />拥抱变化极致高效<br />担当可依赖",
        "lan-dev": "发展历程",
        "lan-2017": "2017年正式成立，是一家专注于IOT领域，集智能电工、智能照明、智能安防、智能小家电、智能配件等硬件产品研发、生产、销售和服务于一体的国家级高新技术企业",
        "lan-2018": "2018年通过ISO9001质量管理体系认证，年销售额过亿，亚马逊垂直类目销售冠军",
        "lan-2019": "2019年年销售增长100%，建立洛杉矶分公司，成立德国和美国售后服务中心，业务覆盖全球31个国家和地区，智造基地升级为15000平方米，月均产能100W以上",
        "lan-2020": "2020年全球设备累计激活千万，年营业额再次100%增长，获得4项红点产品设计大奖",
        "lan-20213": "2021年3月，Gosund SP111和WP5智能插座在“室内设计元素”和“智能产品”两大类别中共获得4项红点产品设计奖",
        "lan-20216": "2021年6月，酷客智能获得“2020年度广东省守合同重信用企业”",
        "lan-20217": "2021年7月，酷客智能获得CFS第十届财经峰会“2021行业影响力品牌”",
        "lan-20218": "2021年7月，陈才（总经理）获得CFS第十届财经峰会“2021行业影响力人物”",
        "lan-3y": "3月",
        "lan-6y": "6月",
        "lan-7y": "7月",
        "lan-phone": "电话",
        "lan-address": "联系地址",
        "lan-address-det": "深圳市宝安区西乡街道固兴社区迪福路12号A栋201、301"
    }
}