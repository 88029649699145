import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Product from "../views/Product.vue";
import Detail from "../views/Detail.vue";
import News from "../views/News.vue";
import Supplier from "../views/Supplier.vue";
import About from "../views/About.vue";
import Test from "../views/Test.vue";
import Mall from "../views/Mall.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/product",
        name: "Product",
        component: Product
    },
    {
        path: "/detail",
        name: "Detail",
        component: Detail
    },
    {
        path: "/news",
        name: "News",
        component: News
    },
    {
        path: "/supplier",
        name: "Supplier",
        component: Supplier
    },
    {
        path: "/about",
        name: "About",
        component: About
    },
    {
        path: "/test",
        name: "Test",
        component: Test
    },
    {
        path: "/mall",
        name: "Mall",
        component: Mall
    }
    // {
    //     path: "/about",
    //     name: "About",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: function () {
    //         return import(/* webpackChunkName: "about" */ "../views/About.vue");
    //     },
    // },
];

const router = new VueRouter({
    routes,
    //mode: 'history',   //去除#号
});

//重复点击路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router;
