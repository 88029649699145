<template>
    <div class="product">
        <Top :current="currentIndex" />

        <div class="bor" :style="{ height: mainHeight }">
            <p class="til">
                <span>{{$t('product.lan-product')}}</span>
                <span> / </span>
                <span v-if="types === 1">{{$t('home.lan-electrician')}}</span>
                <span v-if="types === 2">{{$t('home.lan-illumination')}}</span>
                <span v-if="types === 3">{{$t('home.lan-accessories')}}</span>
            </p>

            <ul class="mains" ref="con">
                <li v-for="(item, index) in data" :key="index">
                    <router-link :to="{path:'/detail', query:{detId:item.thisId}}">
                        <img :src="item.img" alt="">
                        <h3>{{item.name}}</h3>
                        <p>{{$t('product.lan-more')}}>></p>
                    </router-link>
                </li>
            </ul>
        </div>

        <Footer />
    </div>
</template>

<script>
import Top from '@/components/Top.vue'
import Footer from "@/components/Footer.vue"

export default {
    components: {
        Top,
        Footer
    },
    name: "Product",
    data() {
        return {
            currentIndex: 1,
            types: 1,
            data: [],
            mainHeight: 0,
            dataElectrician: [
                {
                    img: require('../assets/img/pro/e/CP1/cp1.png'),
                    thisId: 1,
                    name: 'CP1'
                },
                {
                    img: require('../assets/img/pro/e/CP1-AM/cp1-AM.png'),
                    thisId: 2,
                    name: 'CP1-AM'
                },
                {
                    img: require('../assets/img/pro/e/CP2/cp2.png'),
                    thisId: 3,
                    name: 'CP2'
                },
                {
                    img: require('../assets/img/pro/e/CP2-AM/CP2-AM.png'),
                    thisId: 4,
                    name: 'CP2-AM'
                },
                {
                    img: require('../assets/img/pro/e/CP3/cp3.png'),
                    thisId: 5,
                    name: 'CP3'
                },
                {
                    img: require('../assets/img/pro/e/CP3-AM/CP3-AM.png'),
                    thisId: 6,
                    name: 'CP3-AM'
                },
                {
                    img: require('../assets/img/pro/e/CP5/CP5.png'),
                    thisId: 7,
                    name: 'CP5'
                },
                {
                    img: require('../assets/img/pro/e/CP5-B/CP5-B.png'),
                    thisId: 8,
                    name: 'CP5-B'
                },
                {
                    img: require('../assets/img/pro/e/CP5PRO/CP5PRO.png'),
                    thisId: 9,
                    name: 'CP5 PRO'
                },
                {
                    img: require('../assets/img/pro/e/CS1/CS1.png'),
                    thisId: 10,
                    name: 'CS1'
                },
                {
                    img: require('../assets/img/pro/e/CS2/CS2.png'),
                    thisId: 11,
                    name: 'CS2'
                },
                {
                    img: require('../assets/img/pro/e/CS3/CS3.png'),
                    thisId: 12,
                    name: 'CS3'
                },
                {
                    img: require('../assets/img/pro/e/S4AM/S4AM.png'),
                    thisId: 13,
                    name: 'S4AM'
                },
                {
                    img: require('../assets/img/pro/e/S5AM/S5AM.png'),
                    thisId: 14,
                    name: 'S5AM'
                },
                {
                    img: require('../assets/img/pro/e/S6AM/S6AM.png'),
                    thisId: 15,
                    name: 'S6AM'
                }
            ],
            dataIllumination: [
                {
                    img: require('../assets/img/pro/i/LB3/LB3.png'),
                    thisId: 16,
                    name: 'LB3'
                },
                {
                    img: require('../assets/img/pro/i/LB4/LB4.png'),
                    thisId: 17,
                    name: 'LB4'
                },
                {
                    img: require('../assets/img/pro/i/WB2/WB2.png'),
                    thisId: 18,
                    name: 'WB2'
                },
                {
                    img: require('../assets/img/pro/i/WB5/WB5.png'),
                    thisId: 19,
                    name: 'WB5'
                }
            ],
            dataAccessories: [
                {
                    img: require('../assets/img/pro/a/N1A/N1A.png'),
                    thisId: 20,
                    name: 'N1A'
                }
            ]
        }
    },
    mounted() {
        let id = this.$route.query.productType;
        if(id === 'electrician'){
            this.data = this.dataElectrician
            this.types = 1
        } else if(id === 'illumination'){
            this.data = this.dataIllumination
            this.types = 2
        } else if(id === 'accessories'){
            this.data = this.dataAccessories
            this.types = 3
        }

        let wid = document.documentElement.clientWidth
        if(wid > 1041){
            let mainHei = document.documentElement.clientHeight - 490
            let proLen = this.data.length
            let conHei = Math.ceil(proLen / 4) * 317 + 26
            if(conHei > mainHei) {
                this.mainHeight = 'auto'
            } else{
                this.mainHeight = mainHei + 'px'
            }
        } else{
            this.mainHeight = 'auto'
        }
    },
    methods: {
        
    }
};
</script>

<style lang="scss" scoped>
.bor{
    width: 1040px;
    margin: 50px auto 30px;
    .til{
        width: 100%;
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        a:hover{
            color: #00D3BE;
        }
    }
    ul{
        width: 1120px;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        li{
            width: 200px;
            margin-right: 80px;
            margin-bottom: 40px;
            a{
                display: block;
                width: 100%;
                img{
                    width: 100%;
                }
                h3{
                    width: 100%;
                    height: 21px;
                    line-height: 21px;
                    font-size: 16px;
                    font-weight: normal;
                    text-align: center;
                    margin: 20px 0 10px;
                }
                p{
                    width: 100%;
                    height: 16px;
                    line-height: 16px;
                    font-size: 12px;
                    color: #00D3BE;
                    text-align: center;
                    margin: 10px 0 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .bor{
        width: 91.4%;
        margin: 24px auto 16px;
        .til{
            width: 100%;
            height: auto;
            line-height: 12px;
            font-size: 10px;
            a:hover{
                color: #00D3BE;
            }
        }
        ul{
            width: 100%;
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li{
                width: 47.5%;
                margin-right: 0;
                margin-bottom: 24px;
                a{
                    display: block;
                    width: 100%;
                    img{
                        width: 100%;
                    }
                    h3{
                        width: 100%;
                        height: auto;
                        line-height: 19px;
                        font-size: 14px;
                        font-weight: normal;
                        text-align: center;
                        margin: 8px 0;
                    }
                    p{
                        width: 100%;
                        height: auto;
                        line-height: 16px;
                        font-size: 12px;
                        color: #00D3BE;
                        text-align: center;
                        margin: 0;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:768px) and (max-width:1040px) {
    .bor{
        ul{
            li{
                width: 30%;
            }
        }
    }
}
</style>
