module.exports = {
    top: {
        "lan-zh": "中文",
        "lan-en": "English",
        "lan-index": "Home",
        "lan-pro": "Product",
        "lan-news": "News",
        "lan-suppier": "Join us",
        "lan-about": "About",
        "lan-company": "Company News",
        "lan-staff": "Employee Activity",
        "lan-intr": "CUCO Smart",
        "lan-culture": "CUCO Culture",
        "lan-develop": "History of Development",
        "lan-connect": "Contact us"
    },
    footer: {
        "lan-ind": "Home",
        "lan-pro": "Product",
        // "lan-switch": "Switchs",
        // "lan-plug": "Plugs",
        // "lan-light": "Lights",
        "lan-electrician": "Smart Electricity",
        "lan-illumination": "Smart Lighting",
        "lan-accessories": "Digital Accessories",
        "lan-app": "APP Download",
        "lan-news": "News",
        "lan-newCompany": "Company News",
        "lan-newStaff": "Employee Activity",
        "lan-supplier": "Join us",
        "lan-about": "About",
        "lan-intr": "CUCO Smart",
        "lan-culture": "CUCO Culture",
        "lan-path": "History of Development",
        "lan-connect": "Contact us",
        "lan-icp": "Yue ICP"
    },
    home: {
        "lan-banner-1": 'Dianxiaoku is a newly created brand under Shenzhen CUCO Smart Technology Co. Ltd. (referred to as "CUCO Smart"). The main products include fast charging plugs, fast charging cables, power banks, sockets, etc. All product technologies of the Dianxiaoku brand are developed and produced by the Cuco Smart R&D Center and are committed to creating better and more comfortable products for user experience.',
        "lan-banner-1-1": 'Dianxiaoku is a newly created brand under Shenzhen CUCO Smart Technology Co. Ltd.',
        "lan-banner-1-2": '(referred to as "CUCO Smart") The main products include fast charging plugs, fast charging',
        "lan-banner-1-3": "cables, power banks, sockets, etc.All product technologies of the Dianxiaoku brand are",
        "lan-banner-1-4": "developed and produced by the CUCO Smart R&DCenter and are committed to creating",
        "lan-banner-1-5": "better and more comfortable products for user experience.",
        "lan-banner-2": "So Fast, So Cool",
        "lan-banner-3": "The Ambilight<br />Life in The<br />Palm of<br />Your Hand",
        "lan-banner-4": "The future is Accessible",
        "lan-electrician": "Smart Electricity",
        "lan-illumination": "Smart Lighting",
        "lan-accessories": "Digital Accessories",
        "lan-cp1-1": "Dianxiaoku",
        "lan-cp1-2": "Smart Plug CP1",
        "lan-cp1-3": "Start Your Smart Life",
        "lan-cp5": "Dianxiaoku Smart Plug<br />Power Strip CP5 PRO",
        "lan-cp5-1": "Dianxiaoku",
        "lan-cp5-2": "Smart Plug Power Strip CP5 PRO",
        "lan-cp5-3": "65W GaN Fast Charging Power Strip",
        "lan-20": "20W Fast<br />Charging Plug",
        "lan-20-1": "20W Fast Charging Plug",
        "lan-20-2": "4 times faster, more than just fast",
        "lan-65": "65W Power<br />Adapter",
        "lan-65-1": "65W Power Adapter",
        "lan-65-2": "Dual TYPE-C ports, energy distribution function",
        "lan-light": "Lighting Design",
        "lan-light-1": "Dimmable Smart Light",
        "lan-light-2": "Bring more color to your home life lighting"
    },
    product: {
        "lan-more": "More information",
        "lan-product": "Product",
        "lan-dowmload": "Down Load",
        "lan-goBuy": "Go Buy",
        "lan-ratedPower": "Rated Power",
        "lan-operatingTemperature": "Operating Temperature",
        "lan-productSize": "Product Size",
        "lan-color": "Color",
        "lan-lightColor": "Light Color",
        "lan-wirelessType": "Wireless Type",
        "lan-fireLevel": "Fire-proof Level",
        "lan-enter": "Enter",
        "lan-lumen": "Lumen",
        "lan-materials": "Material",
        "lan-connector": "Power Connector",
        "lan-screw": "Screw mouth",
        "lan-temperature": "Color temperature value",
        "lan-color-w": "White",
        "lan-color-b": "Black",
        "lan-material": "PC（V0 Fire prevention）",
        "lan-name-1": "Dianxiaoku Smart Plug CP1",
        "lan-name-2": "Dianxiaoku Smart Plug CP1-AM (energy monitor version)",
        "lan-name-3": "Dianxiaoku Smart Plug CP2 (energy monitor version)",
        "lan-name-4": "Dianxiaoku Smart Conversion Plug CP2-AM",
        "lan-name-5": "Dianxiaoku Air Conditioner Companion CP3 (unavailable)",
        "lan-name-6": "Dianxiaoku Smart Conversion Plug CP3-AM",
        "lan-name-7": "Dianxiaoku Smart Plug Power Strip CP5",
        "lan-name-8": "Dianxiaoku Smart Plug Power Strip CP5-B",
        "lan-name-9": "Dianxiaoku GaN Smart Power Strip CP5 PRO",
        "lan-name-10": "Dianxiaoku Smart Wall Switch CS1 (Live Neutral Single Wire Version)",
        "lan-name-11": "Dianxiaoku Smart Wall Switch CS2 (Live Neutral Dual Wire Version)",
        "lan-name-12": "Dianxiaoku Smart Wall Switch CS3 (Live Neutral Triple Wire Version)",
        "lan-name-13": "Dianxiaoku Smart Wall Switch S4AM (Single Live Single Wire Version)",
        "lan-name-14": "Dianxiaoku Smart Wall Switch S5AM (Single Live Dual Wire Version)",
        "lan-name-15": "Dianxiaoku Smart Wall Switch S6AM (Single Live Triple Wire Version)",
        "lan-name-16": "Dianxiaoku Smart Colorful Bedside Lamp LB3",
        "lan-name-17": "Dianxiaoku Smart Star Projection Lamp LB4",
        "lan-name-18": "Dianxiaoku Smart Bulb WB2",
        "lan-name-19": "Dianxiaoku Smart Bulb WB5 (Colorful Version)",
        "lan-name-20": "Dianxiaoku 20W Fast Charging Power Adapter N1A (colorful version)",
        "lan-tips-1": "Access to Mijia APP /  Xiaoai voice assistant control / local timer / smart linkage / power-off memory",
        "lan-tips-2": "Access to Mijia APP / Xiaoai voice assistant control / energy monitor / timer / smart linkage",
        "lan-tips-3": "Access to Mijia APP / Xiaoai voice assistant control / energy monitor / timer / smart linkage",
        "lan-tips-4": "Access Mijia APP / Xiaoai voice assistant control / timer / Smart linkage / Power-off memory",
        "lan-tips-5": "Access to Mijia APP / Xiaoai voice assistant control",
        "lan-tips-6": "Access Mijia APP / Xiaoai voice assistant control / timer / Smart linkage / Power-off memory",
        "lan-tips-7": "18W fast charge / QC3.0 / access to Mijia APP / Xiaoai voice assistant control / timer / smart linkage / 4 outlets independent control",
        "lan-tips-8": "18W fast charge / QC3.0 / access to Mijia APP / Xiaoai voice assistant control / timer / smart linkage / 4 outlets independent control",
        "lan-tips-9": "65W fast charge / GaN fast charging technology / access to Mijia APP / Xiaoai voice assistant control / local timer / smart linkage / 4 outlets independent control",
        "lan-tips-10": "Standard 86 type bottom box / access to Mijia APP / Xiaoai voice assistant control / timer / smart linkage / power off memory",
        "lan-tips-11": "Standard 86 type bottom box / access to Mijia APP / Xiaoai voice assistant control / timer / smart linkage / power off memory",
        "lan-tips-12": "Standard 86 type bottom box / access to Mijia APP / Xiaoai voice assistant control / timer / smart linkage / power off memory",
        "lan-tips-13": "Standard 86 type bottom box / access to Mijia APP / Xiaoai voice assistant control / timer / smart linkage / power off memory",
        "lan-tips-14": "Standard 86 type bottom box / access to Mijia APP / Xiaoai voice assistant control / timer / smart linkage / power off memory",
        "lan-tips-15": "Standard 86 type bottom box / access to Mijia APP / Xiaoai voice assistant control / timer / smart linkage / power off memory",
        "lan-tips-16": "Access to Tuya APP / voice control / tap control / timer / sync to music / plan mode",
        "lan-tips-17": "Access to Tuya APP / voice control / timer / sync to music / 360° dynamic projection / 4 effect modes",
        "lan-tips-18": "E27 universal screw port / access to Mijia APP / Xiaoai voice assistant control / timer / smart linkage / scene mode / power off memory",
        "lan-tips-19": "E27 universal screw port / access to Mijia APP / Xiaoai voice assistant control / timer / smart linkage / scene mode / power off memory",
        "lan-tips-20": "20W high power / 400% power speedup / fast charging & not easy to get hot / mini size / trickle charging / DXK PI technology / 3 years only for replacement without repair"
    },
    news: {
        "lan-news-1": "Employees focus on training, running in the morning.",
        "lan-news-2": "The racing of Dragon Boat Festival.",
        "lan-news-3": "Diathesis developing activity, team collaboration."
    },
    supplier: {
        "lan-img-intr-1": "Global investment to join",
        "lan-img-intr-2": "Smart home trillion-level market development",
        "lan-img-intr-3": "Recruitment of Dianxiaoku super partners",
        "lan-img-intr-4": "E-commerce channel distribution | Offline channel cooperation | Pioneer in the IOT field",
        "lan-img-intr-5": "Market Prospect",
        "lan-img-intr-6": "According to Statista data calculations, the smart home market size in 2020 is about 434.5 billion yuan, and it is expected to exceed 800 billion yuan in 2025, which is in line with a growth rate of 15.8%. From the perspective of the scale of China's market segments, the original market for smart home appliances is large, the development of intelligence is early, and its penetration rate is high. Smart home appliances account for the largest proportion, with a revenue of 282.2 billion yuan.",
        "lan-til-1": "Advantages of Dianxiaoku",
        "lan-til-2": "Contact Us",
        "lan-adv-1": "Formally established in 2017, it is a national high-tech enterprise focusing on the field of IoT, integrating the R&D, production, sales, and service of hardware products such as smart electricity, smart lighting, smart security, smart small home appliances, smart accessories, etc.",
        "lan-adv-2": "Passed the ISO9001 quality management system certification in 2018. With annual sales exceeding 100 million yuan and won the champion of the Amazon vertical category sales.",
        "lan-adv-3": "In 2019, the annual sales increased by 100% and created the Los Angeles branch. The German and American after-sales service centers were established. The business covers 31 countries and regions around the world, the area of smart manufacturing base was upgraded to 15,000 square meters, and the average monthly production capacity was more than 100W.",
        "lan-adv-4": "In 2020, there will be a total of tens of millions of global equipment activations, and the annual turnover will increase by 100% again and win 4 Red Dot Design Awards.",
        "lan-name": "Name",
        "lan-pho": "Contact Us",
        "lan-name-err": "Please type in your name",
        "lan-pho-err": "Please enter contact information",
        "lan-sure": "OK",
        "lan-suc": "Information submitted successfully"
    },
    about: {
        "lan-intr-1": 'Dianxiaoku is a sub-brand established by Shenzhen CUCO Intelligent Technology Co., Ltd. ("CUCO Smart" for short) in 2021. It was born for the domestic market, focusing on the R&D, production, sales and service of hardware products such as smart home appliances and smart accessories.',
        "lan-intr-2": '"CUCO Smart" was formally established in 2017, it is a national high-tech enterprise focusing on the field of IoT, integrating the R&D, production, sales, and service of hardware products such as smart electricity, smart lighting, smart security, smart small home appliances, smart accessories, etc.',
        "lan-mission": "Mission",
        "lan-vision": "Vision",
        "lan-values": "Values",
        "lan-mission-det": "Create a smart and comfortable living space for global users",
        "lan-vision-det": "Serve 100 million households worldwide by 2030",
        "lan-values-det": "User-centered, embrace change, be extremely efficient, be responsible and dependable",
        "lan-values-det-mob": "User-centered, embrace change, be extremely efficient, be responsible and dependable",
        "lan-dev": "Development Path",
        "lan-2017": "Formally established in 2017, it is a national high-tech enterprise focusing on the field of IoT, integrating the R&D, production, sales, and service of hardware products such as smart electricity, smart lighting, smart security, smart small home appliances, smart accessories, etc.",
        "lan-2018": "Passed the ISO9001 quality management system certification in 2018. With annual sales exceeding 100 million yuan and won the champion of the Amazon vertical category sales.",
        "lan-2019": "In 2019, the annual sales increased by 100% and created the Los Angeles branch. The German and American after-sales service centers were established. The business covers 31 countries and regions around the world, the area of smart manufacturing base was upgraded to 15,000 square meters, and the average monthly production capacity was more than 100W.",
        "lan-2020": "In 2020, there will be a total of tens of millions of global equipment activations, and the annual turnover will increase by 100% again and win 4 Red Dot Design Awards.",
        "lan-20213": 'In March 2021, Gosund SP111 and WP5 smart sockets won a total of 4 red dot product design awards in the two categories of "interior design elements" and "smart products"',
        "lan-20216": 'In June 2021, CUCO Smart was awarded the "2020 Guangdong Province Contract-abiding and Credit-Respecting Enterprise"',
        "lan-20217": 'In July 2021, CUCO Smart won the "2021 Industry Influential Brand" at the 10th CFS Financial Summit',
        "lan-20218": 'In July 2021, Chencai (General Manager) was awarded "2021 Industry Influential Person" at the 10th CFS Financial Summit',
        "lan-3y": "March",
        "lan-6y": "June",
        "lan-7y": "July",
        "lan-phone": "Tel",
        "lan-address": "Address",
        "lan-address-det": "201 and 301, Building A, No. 12, Difu Road, Guxing Community, Xixiang Street, Baoan District, Shenzhen"
    }
}